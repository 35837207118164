var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      staticClass: "subletinfo-modal-wrapper",
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [_c("DefaultModalTitle", [_vm._v("Andrahandsuthyrning")])],
        1
      ),
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "body-wrapper" }, [
          _c("section", { staticClass: "body-section" }, [
            _c("h2", { staticClass: "title" }, [
              _vm._v("Kul att du är intresserad av Andrahand!"),
            ]),
            _c("section", { staticClass: "modal-bullets-container" }, [
              _c("p", { staticClass: "contact" }, [
                _vm._v(" Kontakta Morgan för att komma igång: "),
                _c("br"),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "mailto:morgan@lagenhetsbyte.se?subject=Bytesansökan",
                    },
                  },
                  [_vm._v("morgan@lagenhetsbyte.se")]
                ),
                _c("br"),
                _vm._v(" 070-815 61 74 "),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "BaseButton",
            {
              attrs: { "is-rounded": true, "is-default": true },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Stäng")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }