import http from '@/services/http';
import statusTypes from '@/utils/statusTypes';

async function fetchApps(ctx) {
  ctx.commit('setAppsLoading', true);
  try {
    const res = await http.getApplications();
    ctx.commit('setApps', res);
  } catch {
    console.error('Failed to fetch applications');
  }
  ctx.commit('setAppsLoading', false);
}

async function fetchSubletApps(ctx) {
  ctx.commit('setAppsLoading', true);
  const res = await http.getSubletApplications();
  ctx.commit('mergeAhApps', res);
  ctx.commit('setAppsLoading', false);
}

async function fetchRemovedApps(ctx) {
  const res = await http.getRemovedApplications();
  ctx.commit('setRemovedApps', res);
}

async function fetchAhArchivedApps(ctx) {
  ctx.commit('setAppsLoading', true);
  const res = await http.getArchivedAhApplications();
  ctx.commit('mergeAhApps', res);
  ctx.commit('setAppsLoading', false);
}

async function abortApp(ctx, { id }) {
  try {
    await http.abortApplication(id);

    ctx.commit('setApplicationStatus', {
      id,
      status: statusTypes.ABORTED
    });
  } catch {
    console.error('Failed to ABORT application');
  }
}

async function approveApp(ctx, { id }) {
  try {
    await http.approveApplication(id);

    ctx.commit('setApplicationStatus', {
      id,
      status: statusTypes.APPROVED
    });
  } catch {
    console.error('Failed to APPROVE application');
  }
}

async function restoreApp(ctx, id) {
  try {
    await http.restoreApplication(id);

    ctx.commit('setApplicationStatus', {
      id,
      status: statusTypes.READY
    });
  } catch {
    console.error('Failed to RESTORE application');
  }
}

async function approveAhApp(ctx, { id }) {
  try {
    await http.approveAhApplication(id);

    ctx.commit('setAhApplicationStatus', {
      id,
      status: statusTypes.APPROVED
    });
  } catch {
    console.error('Failed to APPROVE application');
  }
}

async function rejectApp(ctx, { id, note }) {
  try {
    await http.rejectApplication(id, note);

    ctx.commit('setApplicationStatus', {
      id,
      status: statusTypes.REJECTED
    });
  } catch {
    console.error('Failed to REJECT application');
  }
}

async function rejectAhApp(ctx, { id, note }) {
  try {
    await http.rejectAhApplication(id, note);

    ctx.commit('setAhApplicationStatus', {
      id,
      status: statusTypes.REJECTED
    });
  } catch {
    console.error('Failed to REJECT application');
  }
}

async function fetchLandlordUsersWithUnassigned(ctx) {
  try {
    let users = await http.getLandlordUsers();
    const unassignedUser = {
      firstName: 'Ej',
      lastName: 'Tilldelad',
      fullName: 'Ej Tilldelad',
      id: null,
      selected: false
    };
    users = users.map(user => ({
      ...user,
      fullName: user.firstName + ' ' + user.lastName,
      selected: false
    }));
    ctx.commit('setLandlordUsers', [...users, unassignedUser]);
  } catch {
    console.error('Failed to fetch landlord users');
  }
}

async function removeApp(ctx, { id, note }) {
  try {
    await http.removeApplication(id, note);

    ctx.commit('setApplicationStatus', {
      id,
      status: 'REMOVED'
    });
  } catch {
    console.error('Failed to REMOVE application');
  }
}

async function removeAhApp(ctx, { id, note }) {
  try {
    await http.removeAhApplication(id, note);

    ctx.commit('setAhApplicationStatus', {
      id,
      status: 'REMOVED'
    });
  } catch {
    console.error('Failed to REMOVE application');
  }
}

async function cancelRemoveApp(ctx, id) {
  try {
    const prevStatus = await http.cancelDeleteApplication(id);

    ctx.commit('setApplicationStatus', {
      id,
      status: prevStatus
    });
  } catch {
    console.error('Failed to CANCEL REMOVE application');
  }
}

async function clearFilter({ commit, state }) {
  commit('resetFilterTags');
  commit(
    'setFilterCategories',
    state.filterCategories?.map(status => ({
      ...status,
      selected: false
    }))
  );
  commit(
    'setSubletFilterCategories',
    state.subletFilterCategories?.map(status => ({
      ...status,
      selected: false
    }))
  );
  commit(
    'setLandlordUsers',
    state.landlordUsers?.map(user => ({
      ...user,
      selected: false
    }))
  );
  commit('setDatePickerValue', {
    type: 'newCaseFromDate',
    value: null
  });
  commit('setDatePickerValue', {
    type: 'newCaseToDate',
    value: null
  });
  commit('setDatePickerValue', {
    type: 'swapFromDate',
    value: null
  });
  commit('setDatePickerValue', {
    type: 'swapToDate',
    value: null
  });
}

async function setAhRentPeriod(ctx, { id, startDate, endDate }) {
  await http.setRentPeriod(id, { startDate, endDate });

  ctx.commit('setAhApplicationDates', {
    id,
    startDate,
    endDate
  });
}

async function closeAhApp(ctx, { id }) {
  try {
    await http.closeAhApplication(id);

    ctx.commit('setAhApplicationStatus', {
      id,
      status: statusTypes.CLOSED
    });
  } catch {
    console.error('Failed to CLOSE ah application');
  }
}

export default {
  fetchApps,
  fetchSubletApps,
  fetchRemovedApps,
  fetchAhArchivedApps,
  approveApp,
  restoreApp,
  approveAhApp,
  rejectApp,
  rejectAhApp,
  fetchLandlordUsersWithUnassigned,
  abortApp,
  removeApp,
  removeAhApp,
  cancelRemoveApp,
  clearFilter,
  setAhRentPeriod,
  closeAhApp
};
