import axios from 'axios';

let baseUrl = 'https://api.bytesansokan.se';

if (location.host.includes('localhost')) {
  baseUrl = 'http://localhost:3001';
} else if (location.host.includes('test') || location.host.includes('demo')) {
  baseUrl = 'https://dev-api.bytesansokan.se';
}

const apiEndpoint = baseUrl;

const apiInstance = axios.create({
  baseURL: apiEndpoint,
  withCredentials: true,
  'content-Type': 'application/json'
});

async function handleUnauth(error) {
  if (error.response && error.response.status === 401) {
    await logout();
    window.location.reload();
  } else {
    throw error;
  }
}

async function getApplications() {
  try {
    const res = await apiInstance.get('/applications');
    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}
async function getRemovedApplications() {
  try {
    const res = await apiInstance.get('/applications/removed');
    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function getArchivedAhApplications() {
  try {
    const res = await apiInstance.get('/andrahand/portal/applications/archive');
    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function getUserAhApplications(userId) {
  try {
    const res = await apiInstance.get(
      `/andrahand/portal/applications/history/${userId}`
    );
    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function getApplication(id) {
  try {
    const res = await apiInstance.get(`/applications/${id}`);
    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function getSubletApplication(id) {
  try {
    const res = await apiInstance.get(`/andrahand/portal/applications/${id}`);
    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function assignLandlordUser(id, newUserId, ah) {
  try {
    const res = await apiInstance.put(
      `/applications/${id}/assign-user/${newUserId}${ah ? '?ah=true' : ''}`
    );

    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function assignSecondaryLandlordUser(id, newUserId, ah) {
  try {
    const res = await apiInstance.put(
      `/applications/${id}/assign-secondary-user/${newUserId}${
        ah ? '?ah=true' : ''
      }`
    );

    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function unassignLandlordUser(id, ah) {
  try {
    const res = await apiInstance.put(
      `/applications/${id}/unassign-user${ah ? '?ah=true' : ''}`
    );

    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function unassignSecondaryLandlordUser(id, ah) {
  try {
    const res = await apiInstance.put(
      `/applications/${id}/unassign-secondary-user${ah ? '?ah=true' : ''}`
    );

    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function login(credentials) {
  try {
    const res = await apiInstance.post('/account/login', credentials);
    return res;
  } catch (error) {
    return null;
  }
}

async function changePassword(newPassword, uuid) {
  return await apiInstance.post(`/account/new-password/${uuid}`, {
    password: newPassword
  });
}

function requestPasswordReset(username) {
  return apiInstance.post(`/account/reset-password/`, {
    username: username
  });
}

async function logout() {
  try {
    return await apiInstance.post('/account/logout');
  } catch (error) {
    console.log(error);
    return null;
  }
}

async function getAccount() {
  try {
    const { data } = await apiInstance.get('/account/');
    return data;
  } catch (error) {
    console.log('Unauthorized');
  }
  return null;
}

async function getLandlordUsers() {
  try {
    const { data } = await apiInstance.get('/landlords/users');
    return data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function getTutorial(path) {
  try {
    const res = await apiInstance.get(path);
    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function putTutorial(path, data) {
  try {
    return await apiInstance.put(path, data);
  } catch (error) {
    return handleUnauth(error);
  }
}

async function getSwapTree(applicationId) {
  try {
    const { data } = await apiInstance.get(`/swapTree/render/${applicationId}`);
    return data;
  } catch (error) {
    return null;
  }
}

async function getMapCoordinates(applicationId) {
  const { data } = await apiInstance.get(`/map/${applicationId}`);
  return data;
}

async function getAhMapCoordinates(applicationId) {
  const { data } = await apiInstance.get(`/map/ah/${applicationId}`);
  return data;
}

async function getNotes(applicationId, types = null) {
  const res = await apiInstance.get(
    `/notes/${applicationId}?${types
      .map((n, index) => `types[${index}]=${n}`)
      .join('&')}`
  );
  return res.data;
}

async function getApplicationLog(applicationId, ah = false) {
  const res = await apiInstance.get(
    `/applications/log/${applicationId}${ah ? '?ah=true' : ''}`
  );
  return res.data;
}

async function saveNote(applicationId, type, note) {
  try {
    const { data } = await apiInstance.post(`/notes`, {
      applicationId,
      type,
      note
    });

    return data;
  } catch (error) {
    return null;
  }
}

async function editNote(applicationId, note, noteId) {
  const { data } = await apiInstance.post(`/notes/edit/${applicationId}`, {
    note,
    noteId
  });

  return data;
}

async function deleteNote(applicationId, noteId) {
  const { data } = await apiInstance.delete(
    `/notes/${applicationId}/${noteId}`
  );

  return data;
}

async function addCheck(applicationId, memberId, type) {
  try {
    const { data } = await apiInstance.post(
      `/applications/${applicationId}/check/${memberId}/${type}`
    );
    return data;
  } catch (error) {
    return null;
  }
}

async function removeCheck(applicationId, memberId, type) {
  try {
    const { data } = await apiInstance.delete(
      `/applications/${applicationId}/check/${memberId}/${type}`
    );
    return data;
  } catch (error) {
    return null;
  }
}

async function removeApplication(applicationId, reason = '') {
  try {
    const res = await apiInstance.post(
      `/applications/${applicationId}/remove`,
      {
        reason
      }
    );
    return res.status === 200;
  } catch (error) {
    return null;
  }
}

async function removeAhApplication(applicationId, reason = '') {
  try {
    const res = await apiInstance.post(
      `/andrahand/portal/applications/${applicationId}/remove`,
      {
        reason
      }
    );
    return res.status === 200;
  } catch (error) {
    return null;
  }
}

async function rejectApplication(applicationId, note) {
  try {
    const res = await apiInstance.post(
      `/applications/${applicationId}/reject`,
      {
        note
      }
    );
    return res.status === 200;
  } catch (error) {
    return null;
  }
}

async function rejectAhApplication(applicationId, note) {
  try {
    const res = await apiInstance.post(
      `/andrahand/portal/applications/${applicationId}/reject`,
      {
        note
      }
    );
    return res.status === 200;
  } catch (error) {
    return null;
  }
}

async function closeAhApplication(applicationId, note) {
  return await apiInstance.post(
    `/andrahand/portal/applications/${applicationId}/close`,
    {
      note
    }
  );
}

async function abortApplication(applicationId) {
  try {
    const res = await apiInstance.post(`/applications/${applicationId}/abort`);
    return res.status === 200;
  } catch (error) {
    return null;
  }
}

async function cancelDeleteApplication(applicationId) {
  try {
    const res = await apiInstance.post(
      `/applications/${applicationId}/abort-remove`
    );
    return res.data;
  } catch (error) {
    return null;
  }
}

async function approveApplication(applicationId) {
  try {
    const res = await apiInstance.post(
      `/applications/${applicationId}/approve`
    );
    return res.status === 200;
  } catch (error) {
    return null;
  }
}

async function restoreApplication(applicationId) {
  await apiInstance.post(`/applications/${applicationId}/restore`);
}

async function approveAhApplication(applicationId) {
  try {
    const res = await apiInstance.post(
      `/andrahand/portal/applications/${applicationId}/approve`
    );
    return res.status === 200;
  } catch (error) {
    return null;
  }
}

async function setRentPeriod(applicationId, { startDate, endDate }) {
  const res = await apiInstance.put(
    `/andrahand/portal/applications/${applicationId}/rentPeriod`,
    {
      startDate,
      endDate
    }
  );
  return res;
}

async function getDocuments(applicationId) {
  const res = await apiInstance.get(`/documents/${applicationId}`);
  return res.data;
}

async function getLbPreviewLink(applicationId, propositionId) {
  try {
    const res = await apiInstance.get(
      `/lb/preview/${applicationId}/${propositionId}`
    );
    return res.data;
  } catch (error) {
    return null;
  }
}

async function mergeAhDocuments({
  applicationId,
  documentIds,
  landlordDocs,
  scrive,
  abortController
}) {
  const res = await apiInstance.get(
    `/andrahand/portal/documents/pdf/merge/${applicationId}`,
    {
      params: {
        documentIds,
        ...(landlordDocs && { landlordDocs: landlordDocs }),
        ...(scrive && { scrive: true })
      },
      ...(abortController && { signal: abortController.signal })
    }
  );
  return res.data;
}

async function mergeDocuments({
  applicationId,
  documentIds,
  landlordDocs,
  scrive,
  abortController
}) {
  const res = await apiInstance.get(`/documents/pdf/merge/${applicationId}`, {
    params: {
      ...(documentIds && { documentIds }),
      ...(landlordDocs && { landlordDocs }),
      ...(scrive && { scrive: true })
    },
    ...(abortController && { signal: abortController.signal })
  });
  return res.data;
}

async function generalStatistics() {
  return apiInstance.get(`landlords/statistics`);
}

async function swapTypesStatistics() {
  return apiInstance.get(`landlords/swap-types`);
}

async function userStatsStatistics() {
  return apiInstance.get(`landlords/user-stats`);
}

async function storeLandlordFile(applicationId, file) {
  const formData = new FormData();
  formData.append('applicationId', applicationId);
  formData.append('file', file);

  const { data } = await apiInstance.put(
    `/landlords/files/${applicationId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

  return data;
}

async function getLandlordFiles(applicationId) {
  const { data } = await apiInstance.get(`/landlords/files/${applicationId}`);
  return data;
}

async function deleteLandlordFile(applicationId, fileName) {
  return await apiInstance.delete(`/landlords/files/${applicationId}`, {
    data: {
      fileName: fileName
    }
  });
}

async function getSubletApplications() {
  try {
    const res = await apiInstance.get('/andrahand/portal/applications');
    return res.data;
  } catch (error) {
    return handleUnauth(error);
  }
}

async function getFile(applicationId, uuid) {
  const { data } = await apiInstance.get(
    `/andrahand/portal/documents/${applicationId}/${uuid}`
  );
  return data;
}

async function getAvailableTags(type = 'ba') {
  const { data } = await apiInstance.get(`/applications/tags?type=${type}`);
  return data;
}

async function addTag(applicationId, tagId) {
  const { data } = await apiInstance.put(
    `/applications/tags/${applicationId}/${tagId}`
  );
  return data;
}

async function removeTag(applicationId, tagId) {
  const { data } = await apiInstance.delete(
    `/applications/tags/${applicationId}/${tagId}`
  );
  return data;
}

export default {
  apiEndpoint,
  approveApplication,
  restoreApplication,
  approveAhApplication,
  rejectApplication,
  rejectAhApplication,
  getAccount,
  login,
  logout,
  getApplication,
  getApplications,
  getRemovedApplications,
  getArchivedAhApplications,
  getSwapTree,
  getNotes,
  getApplicationLog,
  saveNote,
  editNote,
  deleteNote,
  getDocuments,
  getLandlordUsers,
  assignLandlordUser,
  assignSecondaryLandlordUser,
  unassignLandlordUser,
  unassignSecondaryLandlordUser,
  addCheck,
  removeCheck,
  changePassword,
  requestPasswordReset,
  getLbPreviewLink,
  getMapCoordinates,
  getAhMapCoordinates,
  mergeDocuments,
  mergeAhDocuments,
  generalStatistics,
  swapTypesStatistics,
  userStatsStatistics,
  getTutorial,
  putTutorial,
  storeLandlordFile,
  getLandlordFiles,
  deleteLandlordFile,
  removeApplication,
  removeAhApplication,
  abortApplication,
  cancelDeleteApplication,
  getSubletApplications,
  getSubletApplication,
  getFile,
  setRentPeriod,
  closeAhApplication,
  getAvailableTags,
  addTag,
  removeTag,
  getUserAhApplications
};
