var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading && _vm.errorModalVisible
    ? _c("ErrorModal", {
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      })
    : _vm.isLoading
    ? _c(
        "div",
        { staticClass: "loading-wrapper" },
        [_c("BaseLoadingSpinner", { attrs: { "with-overlay": false } })],
        1
      )
    : _c(
        "ApplicationContainerModal",
        _vm._g(
          {
            directives: [
              {
                name: "esc-click",
                rawName: "v-esc-click",
                value: _vm.handleEscClick,
                expression: "handleEscClick",
              },
            ],
          },
          _vm.$listeners
        ),
        [
          _c(
            "template",
            { slot: "modals" },
            [
              _vm.currentModal === "NOTES"
                ? _c("OvNotesModal", {
                    attrs: {
                      "unread-notes": _vm.application.unreadNotes,
                      "application-id": `${_vm.application.id}`,
                      "read-only-mode": false,
                      notes: _vm.application.landlordNotes,
                    },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      addNote: _vm.handleAddNote,
                      deleteNote: _vm.handleDeleteNote,
                      editNote: _vm.handleEditNote,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "DOCUMENTS"
                ? _c("OvDocumentsModal", {
                    attrs: {
                      "application-id": `${_vm.application.id}`,
                      "selected-name": _vm.applicantName,
                      "current-tenant-name": _vm.currentTenants[0].name,
                      "secondary-tenant-name": _vm.secondaryTenants[0].name,
                      files: _vm.application.files,
                      "selected-type": _vm.selectedDocumentType,
                      "selected-document-category-type":
                        _vm.selectedDocumentCategoryType,
                      "selected-applicant-id": _vm.selectedApplicantId,
                      "has-scrive-document": !!_vm.application.scriveId,
                    },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                        _vm.selectedApplicantId = null
                        _vm.selectedDocumentType = null
                        _vm.selectedDocumentCategoryType = null
                      },
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "APPROVE"
                ? _c("OvApprovalModal", {
                    attrs: {
                      "application-id": _vm.application.id,
                      "initial-transfer-date":
                        _vm.application.actualTransferDate,
                      "transfer-date": _vm.application.reason.transferDate,
                      "rent-to": _vm.application.reason.rentTo,
                    },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onApproved: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "REJECT"
                ? _c("OvRejectModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onRejected: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.selectedPartInfo !== null
                ? _c("OvPartInfoModal", {
                    attrs: {
                      position: _vm.selectedPartInfoModalPosition,
                      "selected-info": _vm.selectedPartInfo,
                    },
                    on: {
                      close: function ($event) {
                        _vm.selectedPartInfo = null
                      },
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "USER_NOT_ASSIGNED"
                ? _c("NotAssignedModal", {
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      confirm: function ($event) {
                        _vm.currentModal = "REMOVE"
                      },
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "REMOVE"
                ? _c("OvRemoveModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onRemoved: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "CLOSE"
                ? _c("OvCloseModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      cancelled: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "template",
            { slot: "header" },
            [
              _c("TabHeader", {
                attrs: {
                  "all-disabled": _vm.isAnimatingModalSize,
                  title: _vm.getReasonText,
                  subtitle: _vm.tabHeaderSubtitle,
                  "application-status": _vm.application.landlordStatus,
                  "show-help-button": false,
                  tabs: _vm.activeTabs,
                  "is-archived": _vm.isArchived,
                },
                on: { tabClick: _vm.setTab },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.activeTab === 1
                ? [
                    _vm.isCompanyApplication
                      ? _c("CompanyRows", {
                          attrs: {
                            application: _vm.application,
                            "other-checkboxes": _vm.otherCheckboxes,
                          },
                          on: {
                            openFile: function ($event) {
                              return _vm.openDocumentModal($event)
                            },
                            checksChange: function ($event) {
                              return _vm.updateCheckedMember($event)
                            },
                          },
                        })
                      : _c("PrivateRows", {
                          attrs: {
                            application: _vm.application,
                            "other-checkboxes": _vm.otherCheckboxes,
                          },
                          on: {
                            openFile: function ($event) {
                              return _vm.openDocumentModal($event)
                            },
                            checksChange: function ($event) {
                              return _vm.updateCheckedMember($event)
                            },
                          },
                        }),
                  ]
                : _vm._e(),
              _vm.activeTab === 2
                ? [
                    _vm.userHistory.length
                      ? _c(
                          "div",
                          { staticClass: "grid" },
                          _vm._l(
                            _vm.userHistory,
                            function (userApp, userAppIndex) {
                              return _c(
                                "div",
                                {
                                  key: userApp.id + userAppIndex,
                                  staticClass: "application-card-wrapper",
                                },
                                [
                                  _c("OvApplicationCard", {
                                    attrs: {
                                      application: userApp,
                                      "reason-type-title": _vm.ovReasonText(
                                        userApp.reason
                                      ),
                                      "is-new": false,
                                      "enable-link":
                                        _vm.currentApplicationId !== userApp.id,
                                      "full-name":
                                        userApp.assignedLandlordFirstName,
                                      "last-name":
                                        userApp.assignedLandlordLastName,
                                      "status-date": "",
                                      "show-tag-button": false,
                                      "show-transfer-date": false,
                                      "to-path": _vm.getToPath(userApp.id),
                                    },
                                  }),
                                  _vm.currentApplicationId === userApp.id
                                    ? _c(
                                        "p",
                                        { staticClass: "is-same-card" },
                                        [
                                          _c("ChevronDownIcon"),
                                          _c("span", [
                                            _vm._v("Du har detta ärende öppet"),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _c("div", [
                          _c("h3", { staticStyle: { "font-size": "2rem" } }, [
                            _vm._v("Ingen historik finns ännu"),
                          ]),
                        ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "BaseItemsList",
                  {
                    class: {
                      "no-pointer-events": _vm.hasRemovalTypeStatus,
                    },
                    attrs: {
                      items: _vm.landlordUsersWithUnAssign.filter(
                        (x) => !x.inactivatedAt
                      ),
                      "show-contact": true,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.assignLandlordUser($event)
                      },
                    },
                  },
                  [
                    !_vm.application.assignedLandlordUserId
                      ? _c(
                          "BaseIconText",
                          {
                            staticClass: "assign-button",
                            attrs: {
                              "icon-url": _vm.svgs.ICONS.ADMINISTRATOR,
                              size: "larger",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.hasRemovalTypeStatus
                                    ? "Ej tilldelad"
                                    : "Tilldela handläggare"
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _c("UserIcon", {
                          attrs: {
                            "first-name":
                              _vm.application.assignedLandlordFirstName,
                            "last-name":
                              _vm.application.assignedLandlordLastName,
                            "full-name-visible": true,
                          },
                        }),
                  ],
                  1
                ),
                !_vm.isIncoming && !_vm.hasRemovalTypeStatus
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "simple-button notes-button",
                          on: {
                            click: function ($event) {
                              _vm.currentModal = "NOTES"
                            },
                          },
                        },
                        [
                          _c(
                            "BaseIconText",
                            {
                              attrs: {
                                "icon-url": _vm.svgs.ICONS.NOTEBOOK,
                                size: "larger",
                              },
                            },
                            [
                              false
                                ? _c("NotificationSymbol", {
                                    attrs: {
                                      slot: "icon",
                                      "border-color": "#f1f2f6",
                                    },
                                    slot: "icon",
                                  })
                                : _vm._e(),
                              _vm._v("Noteringar"),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                !_vm.isIncoming && !_vm.hasRemovalTypeStatus
                  ? _c(
                      "button",
                      {
                        staticClass: "simple-button files-button",
                        on: {
                          click: function ($event) {
                            _vm.currentModal = "DOCUMENTS"
                          },
                        },
                      },
                      [
                        _c(
                          "BaseIconText",
                          {
                            attrs: {
                              "icon-url": _vm.svgs.ICONS.ATTACHMENT,
                              size: "larger",
                            },
                          },
                          [
                            _vm._v(
                              "Visa " +
                                _vm._s(_vm.application.files?.length || 0) +
                                " filer "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "right" },
              [
                !_vm.isIncoming && !_vm.hasRemovalTypeStatus
                  ? _c(
                      "div",
                      { staticClass: "popupModal-container mr-15" },
                      [
                        _vm.popupModalVisible
                          ? _c("PopupModal", {
                              attrs: { items: _vm.itemsInPopupModal },
                              on: {
                                close: function ($event) {
                                  _vm.popupModalVisible = false
                                },
                                click: _vm.handlePopupClick,
                              },
                            })
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            staticClass: "simple-button notes-button",
                            on: {
                              click: function ($event) {
                                _vm.popupModalVisible = !_vm.popupModalVisible
                              },
                            },
                          },
                          [
                            _c(
                              "BaseIconText",
                              { attrs: { "icon-url": _vm.svgs.ICONS.MORE } },
                              [_vm._v("Fler val")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.application.landlordStatus === "READY"
                  ? [
                      _c(
                        "BaseButton",
                        {
                          staticClass: "mr-15",
                          attrs: {
                            "is-warning": true,
                            "is-rounded": true,
                            icon: _vm.svgs.ICONS.ERROR_WHITE,
                          },
                          on: {
                            click: function ($event) {
                              _vm.currentModal = "REJECT"
                            },
                          },
                        },
                        [_vm._v(" Neka ansökan ")]
                      ),
                      _c(
                        "BaseButton",
                        {
                          attrs: {
                            "is-rounded": true,
                            icon: _vm.svgs.ICONS.CHECK_WHITE,
                          },
                          on: {
                            click: function ($event) {
                              _vm.currentModal = "APPROVE"
                            },
                          },
                        },
                        [_vm._v(" Godkänn ansökan ")]
                      ),
                    ]
                  : _vm.application.landlordStatus === "APPROVED"
                  ? _c(
                      "BaseButton",
                      {
                        attrs: {
                          "is-warning": true,
                          "is-rounded": true,
                          icon: _vm.svgs.ICONS.ERROR_WHITE,
                        },
                        on: {
                          click: function ($event) {
                            _vm.currentModal = "CLOSE"
                          },
                        },
                      },
                      [_vm._v(" Avsluta uthyrning ")]
                    )
                  : _vm._e(),
                _vm.showRejectReasonModal
                  ? _c(
                      "DefaultModal",
                      {
                        attrs: { "show-close-btn": false },
                        on: {
                          close: function ($event) {
                            _vm.rejectReasonModalVisible = false
                          },
                        },
                      },
                      [
                        _c(
                          "template",
                          { slot: "header" },
                          [_c("DefaultModalTitle", [_vm._v("Nekat byte")])],
                          1
                        ),
                        _c("template", { slot: "body" }, [
                          _c("div", { staticClass: "reject-reason" }, [
                            _c("p", { staticClass: "explanation" }, [
                              _vm._v("Förklaring:"),
                            ]),
                            _vm.landlordRejectionNote.text
                              ? _c("span", [
                                  _vm._v(
                                    '"' +
                                      _vm._s(_vm.landlordRejectionNote.text) +
                                      '"'
                                  ),
                                ])
                              : _c("span", [_vm._v("(Förklaring saknas)")]),
                            _vm.landlordRejectionNote.firstName
                              ? _c("p", [
                                  _vm._v(
                                    " - " +
                                      _vm._s(
                                        _vm.landlordRejectionNote.firstName
                                      ) +
                                      " " +
                                      _vm._s(
                                        _vm.landlordRejectionNote.lastName
                                      ) +
                                      " "
                                  ),
                                ])
                              : _c("p", [_vm._v("(Namn saknas)")]),
                          ]),
                        ]),
                        _c(
                          "template",
                          { slot: "footer" },
                          [
                            _c(
                              "BaseButton",
                              {
                                attrs: {
                                  "is-rounded": true,
                                  "is-default": true,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.rejectReasonModalVisible = false
                                  },
                                },
                              },
                              [_vm._v("Stäng")]
                            ),
                          ],
                          1
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }