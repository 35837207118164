var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DefaultPage", [
    _c("h1", { staticClass: "page-title" }, [_vm._v("Support")]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "card" }, [
        _c("h4", [_vm._v("Kontakt")]),
        _c(
          "a",
          {
            staticClass: "email",
            attrs: { href: "mailto:support@bytesansokan.se" },
          },
          [
            _c(
              "BaseIconText",
              { attrs: { "icon-url": _vm.svgs.ICONS.ENVELOPE } },
              [_vm._v(" support@bytesansokan.se ")]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }