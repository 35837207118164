var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "BaseModal",
    _vm._g(_vm._b({}, "BaseModal", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        "div",
        { ref: "modalElement", staticClass: "applicant-info-wrapper" },
        _vm._l(_vm.selectedInfo, function (person) {
          return _c(
            "div",
            { key: person.index, staticClass: "member-wrapper" },
            [
              _c("h3", { staticClass: "name" }, [
                _vm._v(" " + _vm._s(person.name) + " "),
                person.isRenter
                  ? _c("span", { staticClass: "contract" }, [
                      _vm._v("(Kontraktsinnehavaren)"),
                    ])
                  : person.isRenter === false
                  ? _c("span", { staticClass: "contract" }, [
                      _vm._v("(Medhyresgäst)"),
                    ])
                  : _vm._e(),
              ]),
              _c("span", { staticClass: "address" }, [
                _vm._v(
                  _vm._s(
                    `${person.address}${person.city ? `, ${person.city}` : ""}`
                  )
                ),
              ]),
              _c(
                "span",
                { staticClass: "email" },
                [
                  _c(
                    "BaseIconText",
                    { attrs: { "icon-url": _vm.svgs.ICONS.ENVELOPE } },
                    [_vm._v(" " + _vm._s(person.email || "-") + " ")]
                  ),
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "ssn" },
                [
                  _c(
                    "BaseIconText",
                    {
                      attrs: { "icon-url": _vm.svgs.ICONS.ADMINISTRATOR_GRAY },
                    },
                    [_vm._v(" " + _vm._s(person.ssn || "-") + " ")]
                  ),
                ],
                1
              ),
              _c(
                "span",
                { staticClass: "phone" },
                [
                  _c(
                    "BaseIconText",
                    { attrs: { "icon-url": _vm.svgs.ICONS.PHONE } },
                    [_vm._v(" " + _vm._s(person.phone || "-") + " ")]
                  ),
                ],
                1
              ),
              _vm.onCurrentContractExist(person)
                ? _c(
                    "span",
                    { staticClass: "contract" },
                    [
                      _c(
                        "BaseIconText",
                        {
                          attrs: {
                            "icon-url": person.onCurrentContract
                              ? _vm.svgs.ICONS.CHECK_GREY
                              : _vm.svgs.ICONS.ERROR_GREY,
                          },
                        },
                        [_vm._v(" Står på hyreskontraktet ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }