var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "DefaultModalTitle",
            [
              !_vm.selectedDocumentCategoryType &&
              !["CURRENT_TENANT", "SECONDARY_TENANT"].includes(_vm.currentModal)
                ? [_vm._v("Filer")]
                : [
                    _vm._v(
                      "Filer / " +
                        _vm._s(_vm.selectedName || _vm.currentModalName)
                    ),
                  ],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.generatePdfVisible && !!_vm.documentsCollections
            ? _c("GeneratePdfModal", {
                attrs: {
                  "application-id": _vm.applicationId,
                  "documents-collections": _vm.documentsCollections,
                  "landlord-files": _vm.filesFromId,
                  "has-scrive-document": _vm.hasScriveDocument,
                  "is-ah-or-ov": true,
                },
                on: {
                  close: function ($event) {
                    _vm.generatePdfVisible = false
                  },
                },
              })
            : _vm._e(),
          _vm.additionalFilesModalVisible
            ? _c("AdditionalFilesModal", {
                attrs: { "application-id": _vm.applicationId },
                on: {
                  close: function ($event) {
                    _vm.additionalFilesModalVisible = false
                  },
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "file-browser-wrapper" }, [
            _c(
              "div",
              { staticClass: "file-browser-scroll" },
              [
                !["CURRENT_TENANT", "SECONDARY_TENANT"].includes(
                  _vm.currentModal
                ) &&
                _vm.hasScriveDocument &&
                !_vm.selectedType
                  ? _c(
                      "a",
                      {
                        staticClass: "folder-name",
                        attrs: { href: _vm.scriveLink },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "folder-row" },
                          [
                            _c("BaseImage", {
                              staticClass: "icon",
                              attrs: { url: _vm.svgs.ICONS.PDF },
                            }),
                            _c("div", { staticClass: "folder-info" }, [
                              _vm._v("Signerad ansökan"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.selectedApplicantId !== null
                  ? _c(
                      "div",
                      _vm._l(_vm.documentsByApplicantId, function (file, idx) {
                        return _c(
                          "transition-group",
                          { key: idx, attrs: { name: "shuffle", appear: "" } },
                          [
                            _c(
                              "button",
                              {
                                key: file.fileName,
                                staticClass: "folder-row",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOpenFile(
                                      file.uuid,
                                      file.fileName
                                    )
                                  },
                                },
                              },
                              [
                                _c("BaseImage", {
                                  staticClass: "icon",
                                  attrs: {
                                    url: _vm.docTypeUtils.getFileIcon(
                                      file.fileName
                                    ),
                                  },
                                }),
                                _c("div", { staticClass: "folder-info" }, [
                                  _c("div", { staticClass: "folder-name" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.docTypeUtils.getFilename(
                                            file.fileName
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("div", { staticClass: "file-meta" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.docTypeUtils.getDocTypeStr(
                                            file.type
                                          )
                                        ) +
                                        " / "
                                    ),
                                    _c("span", { staticClass: "file-type" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.docTypeUtils.getFileExtension(
                                            file.fileName
                                          )
                                        )
                                      ),
                                    ]),
                                    _vm._v(
                                      ", " +
                                        _vm._s(
                                          _vm.docTypeUtils.getHumanFileSize(
                                            file.fileSize
                                          )
                                        ) +
                                        " / "
                                    ),
                                    _c("span", { staticClass: "fake-link" }, [
                                      _vm._v("Ladda ner"),
                                    ]),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      1
                    )
                  : ["CURRENT_TENANT", "SECONDARY_TENANT"].includes(
                      _vm.currentModal
                    )
                  ? _c(
                      "div",
                      _vm._l(_vm.getFiles, function (file, idx) {
                        return _c(
                          "transition-group",
                          { key: idx, attrs: { name: "shuffle", appear: "" } },
                          [
                            _c(
                              "button",
                              {
                                key: file.fileName,
                                staticClass: "folder-row",
                                attrs: {
                                  disabled: _vm.downloadingIds.includes(
                                    file.uuid
                                  ),
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOpenFile(
                                      file.uuid,
                                      file.fileName
                                    )
                                  },
                                },
                              },
                              [
                                _c("BaseImage", {
                                  staticClass: "icon",
                                  attrs: {
                                    url: _vm.docTypeUtils.getFileIcon(
                                      file.fileName
                                    ),
                                  },
                                }),
                                _c("div", { staticClass: "folder-info" }, [
                                  _c("div", { staticClass: "folder-name" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.docTypeUtils.getFilename(
                                            file.fileName
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  !_vm.downloadingIds.includes(file.uuid)
                                    ? _c("div", { staticClass: "file-meta" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.docTypeUtils.getDocTypeStr(
                                                file.type
                                              )
                                            ) +
                                            " / "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "file-type" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.docTypeUtils.getFileExtension(
                                                  file.fileName
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                        _vm._v(
                                          ", " +
                                            _vm._s(
                                              _vm.docTypeUtils.getHumanFileSize(
                                                file.fileSize
                                              )
                                            ) +
                                            " / "
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "fake-link" },
                                          [_vm._v("Ladda ner")]
                                        ),
                                      ])
                                    : _c(
                                        "div",
                                        { staticClass: "folder-meta" },
                                        [_vm._v("Laddar ner...")]
                                      ),
                                ]),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      1
                    )
                  : [
                      _c(
                        "button",
                        {
                          staticClass: "folder-row",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.additionalFilesModalVisible = true
                            },
                          },
                        },
                        [
                          _c("BaseImage", {
                            staticClass: "icon",
                            attrs: { url: _vm.svgs.ICONS.FOLDER },
                          }),
                          _c("div", { staticClass: "folder-info" }, [
                            _c("div", { staticClass: "folder-name" }, [
                              _vm._v("Uppladdade filer"),
                            ]),
                            _c("div", { staticClass: "file-meta" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.filesFromId.length) +
                                  " uppladdade filer / "
                              ),
                              _c("span", { staticClass: "upload-files" }, [
                                _vm._v("Ladda upp"),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "folder-row",
                          attrs: {
                            type: "button",
                            disabled: !_vm.currentTenantFiles?.length,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.currentModal = "CURRENT_TENANT"
                            },
                          },
                        },
                        [
                          _c("BaseImage", {
                            staticClass: "icon",
                            attrs: { url: _vm.svgs.ICONS.FOLDER },
                          }),
                          _c("div", { staticClass: "folder-info" }, [
                            _c("div", { staticClass: "folder-name" }, [
                              _vm._v(_vm._s(_vm.currentTenantName)),
                            ]),
                            _c("div", { staticClass: "file-meta" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.currentTenantFiles?.length || 0) +
                                  " uppladdade filer "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "folder-row",
                          attrs: {
                            type: "button",
                            disabled: !_vm.secondaryTenantFiles?.length,
                          },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.currentModal = "SECONDARY_TENANT"
                            },
                          },
                        },
                        [
                          _c("BaseImage", {
                            staticClass: "icon",
                            attrs: { url: _vm.svgs.ICONS.FOLDER },
                          }),
                          _c("div", { staticClass: "folder-info" }, [
                            _c("div", { staticClass: "folder-name" }, [
                              _vm._v(_vm._s(_vm.secondaryTenantName)),
                            ]),
                            _c("div", { staticClass: "file-meta" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.secondaryTenantFiles?.length || 0
                                  ) +
                                  " uppladdade filer "
                              ),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          !["CURRENT_TENANT", "SECONDARY_TENANT"].includes(_vm.currentModal) &&
          !_vm.selectedType
            ? _c(
                "BaseButton",
                {
                  attrs: { "is-rounded": true, icon: _vm.svgs.ICONS.UPLOAD },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.additionalFilesModalVisible = true
                    },
                  },
                },
                [_vm._v(" Ladda upp filer ")]
              )
            : _vm._e(),
          !["CURRENT_TENANT", "SECONDARY_TENANT"].includes(_vm.currentModal) &&
          !_vm.selectedType
            ? _c(
                "div",
                { staticClass: "popupModal-container" },
                [
                  _vm.popupModalVisible
                    ? _c("PopupModal", {
                        attrs: { items: _vm.itemsInPopupModal },
                        on: {
                          close: function ($event) {
                            _vm.popupModalVisible = false
                          },
                          click: _vm.handlePopupClick,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        "is-rounded": true,
                        icon: _vm.svgs.ICONS.DOWNLOAD,
                      },
                      on: {
                        click: function ($event) {
                          _vm.popupModalVisible = !_vm.popupModalVisible
                        },
                      },
                    },
                    [_vm._v(" Ladda ner filer ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          ["CURRENT_TENANT", "SECONDARY_TENANT"].includes(_vm.currentModal) &&
          !_vm.selectedType
            ? _c(
                "a",
                {
                  attrs: {
                    href: _vm.getZipFile(
                      "CURRENT_TENANT" ? "transferor" : "transferee"
                    ),
                  },
                },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        "is-rounded": true,
                        icon: _vm.svgs.ICONS.DOWNLOAD,
                      },
                    },
                    [_vm._v(" Ladda ner som ZIP ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          ["CURRENT_TENANT", "SECONDARY_TENANT"].includes(_vm.currentModal) &&
          !_vm.selectedType
            ? _c(
                "BaseButton",
                {
                  attrs: { "is-rounded": true, "is-default": true },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.currentModal = ""
                    },
                  },
                },
                [_vm._v("Tillbaka")]
              )
            : _vm._e(),
          !["CURRENT_TENANT", "SECONDARY_TENANT"].includes(_vm.currentModal)
            ? _c(
                "BaseButton",
                {
                  attrs: { "is-rounded": true, "is-default": true },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Stäng")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }