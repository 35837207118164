import { render, staticRenderFns } from "./BtnIcon.vue?vue&type=template&id=fea7f2ce"
var script = {}


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fea7f2ce')) {
      api.createRecord('fea7f2ce', component.options)
    } else {
      api.reload('fea7f2ce', component.options)
    }
    module.hot.accept("./BtnIcon.vue?vue&type=template&id=fea7f2ce", function () {
      api.rerender('fea7f2ce', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AppSwitcherDropdown/BtnIcon.vue"
export default component.exports