var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { title: _vm.text } },
    [
      _c("LinkChainIcon", {
        attrs: {
          colors: _vm.reverse ? ["#95032d", "#38389b"] : ["#38389b", "#95032d"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }