var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c(
            "DefaultModalTitle",
            [
              _vm.applicantId === null
                ? [_vm._v("Filer")]
                : [_vm._v("Filer / " + _vm._s(_vm.getActiveFolderName))],
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.currentModal === "GENERATE_PDF" && !!_vm.documentsCollections
            ? _c("GeneratePdfModal", {
                attrs: {
                  "application-id": _vm.applicationId,
                  parts: _vm.parts,
                  "documents-collections": _vm.documentsCollections,
                  "landlord-files": _vm.filesFromId,
                  "has-scrive-document": _vm.hasScriveDocument,
                },
                on: {
                  close: function ($event) {
                    _vm.currentModal = null
                  },
                },
              })
            : _vm._e(),
          _vm.additionalFilesModalVisible
            ? _c("AdditionalFilesModal", {
                attrs: { "application-id": _vm.applicationId },
                on: {
                  close: function ($event) {
                    _vm.additionalFilesModalVisible = false
                  },
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "file-browser-wrapper" }, [
            _c(
              "div",
              { staticClass: "file-browser-scroll" },
              [
                !_vm.applicantId && _vm.hasScriveDocument
                  ? _c(
                      "a",
                      {
                        staticClass: "folder-name",
                        attrs: { href: _vm.scriveLink },
                      },
                      [
                        _c(
                          "FolderRow",
                          [
                            _c("BaseImage", {
                              staticClass: "icon",
                              attrs: { url: _vm.svgs.ICONS.PDF },
                            }),
                            _c("FolderInfo", {
                              attrs: { name: "Signerad ansökan" },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isLoading
                  ? _c("div", [_vm._v("Hämtar dokument...")])
                  : _vm.applicantId !== null
                  ? [
                      _vm.activeFolder
                        ? _c(
                            "div",
                            _vm._l(_vm.activeFolder, function (file) {
                              return _c(
                                "FolderRow",
                                {
                                  key: file.fileName,
                                  attrs: {
                                    href: _vm.getDocumentLink(file.id),
                                    tag: "a",
                                  },
                                  on: { click: _vm.trackFileDownload },
                                },
                                [
                                  _c("BaseImage", {
                                    staticClass: "icon",
                                    attrs: {
                                      url: _vm.docTypeUtils.getFileIcon(
                                        file.fileName
                                      ),
                                    },
                                  }),
                                  _c(
                                    "FolderInfo",
                                    {
                                      attrs: {
                                        name: _vm.docTypeUtils.getFilename(
                                          file.fileName
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.docTypeUtils.getDocTypeStr(
                                              file.type
                                            )
                                          ) +
                                          " / "
                                      ),
                                      _c("span", { staticClass: "file-type" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.docTypeUtils.getFileExtension(
                                              file.fileName
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(
                                        ", " +
                                          _vm._s(
                                            _vm.docTypeUtils.getHumanFileSize(
                                              file.fileSize
                                            )
                                          ) +
                                          " / "
                                      ),
                                      _c("span", { staticClass: "fake-link" }, [
                                        _vm._v("Ladda ner"),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _c(
                            "div",
                            [
                              _vm.documentGroups.memberOne.length
                                ? _c(
                                    "FolderRow",
                                    {
                                      attrs: { tag: "button", type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setActiveMemberIndex(0)
                                        },
                                      },
                                    },
                                    [
                                      _c("BaseImage", {
                                        staticClass: "icon",
                                        attrs: { url: _vm.svgs.ICONS.FOLDER },
                                      }),
                                      _c(
                                        "FolderInfo",
                                        {
                                          attrs: {
                                            name: _vm.activeMembers[0]?.name,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.documentGroups.memberOne
                                                  .length
                                              ) +
                                              " uppladdade filer "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.documentGroups.memberTwo.length
                                ? _c(
                                    "FolderRow",
                                    {
                                      attrs: { tag: "button", type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setActiveMemberIndex(1)
                                        },
                                      },
                                    },
                                    [
                                      _c("BaseImage", {
                                        staticClass: "icon",
                                        attrs: { url: _vm.svgs.ICONS.FOLDER },
                                      }),
                                      _c(
                                        "FolderInfo",
                                        {
                                          attrs: {
                                            name:
                                              _vm.activeMembers[1]?.name ||
                                              "Medsökande",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.documentGroups.memberTwo
                                                  .length
                                              ) +
                                              " uppladdade filer "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._l(
                                _vm.documentGroups.sharedDocs,
                                function (file) {
                                  return _c(
                                    "FolderRow",
                                    {
                                      key: file.fileName,
                                      attrs: {
                                        href: _vm.getDocumentLink(file.id),
                                        tag: "a",
                                      },
                                      on: { click: _vm.trackFileDownload },
                                    },
                                    [
                                      _c("BaseImage", {
                                        staticClass: "icon",
                                        attrs: {
                                          url: _vm.docTypeUtils.getFileIcon(
                                            file.fileName
                                          ),
                                        },
                                      }),
                                      _c(
                                        "FolderInfo",
                                        {
                                          attrs: {
                                            name: _vm.docTypeUtils.getFilename(
                                              file.fileName
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.docTypeUtils.getDocTypeStr(
                                                  file.type
                                                )
                                              ) +
                                              " / "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "file-type" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.docTypeUtils.getFileExtension(
                                                    file.fileName
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            ", " +
                                              _vm._s(
                                                _vm.docTypeUtils.getHumanFileSize(
                                                  file.fileSize
                                                )
                                              ) +
                                              " / "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "fake-link" },
                                            [_vm._v("Ladda ner")]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                    ]
                  : [
                      _c(
                        "FolderRow",
                        {
                          attrs: { tag: "button", type: "button" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              _vm.additionalFilesModalVisible = true
                            },
                          },
                        },
                        [
                          _c("BaseImage", {
                            staticClass: "icon",
                            attrs: { url: _vm.svgs.ICONS.FOLDER },
                          }),
                          _c("FolderInfo", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.filesFromId.length) +
                                " uppladdade filer / "
                            ),
                            _c("span", { staticClass: "upload-files" }, [
                              _vm._v("Ladda upp"),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.documentsCollections,
                        function (collection, id) {
                          return _c(
                            "FolderRow",
                            {
                              key: id,
                              attrs: {
                                tag: "button",
                                type: "button",
                                disabled: !collection?.length,
                              },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  $event.preventDefault()
                                  _vm.applicantId = id
                                },
                              },
                            },
                            [
                              _c("BaseImage", {
                                staticClass: "icon",
                                attrs: { url: _vm.svgs.ICONS.FOLDER },
                              }),
                              _c(
                                "FolderInfo",
                                {
                                  attrs: { name: _vm.getSharedFolderName(id) },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        collection ? collection.length : 0
                                      ) +
                                      " uppladdade filer "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        }
                      ),
                    ],
              ],
              2
            ),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _vm.applicantId == null
            ? _c(
                "BaseButton",
                {
                  attrs: { "is-rounded": true, icon: _vm.svgs.ICONS.UPLOAD },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      _vm.additionalFilesModalVisible = true
                    },
                  },
                },
                [_vm._v(" Ladda upp filer ")]
              )
            : _vm._e(),
          _vm.applicantId == null
            ? _c(
                "div",
                { staticClass: "popupModal-container" },
                [
                  _vm.popupModalVisible
                    ? _c("PopupModal", {
                        attrs: { items: _vm.itemsInPopupModal },
                        on: {
                          close: function ($event) {
                            _vm.popupModalVisible = false
                          },
                          click: _vm.handlePopupClick,
                        },
                      })
                    : _vm._e(),
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        "is-rounded": true,
                        icon: _vm.svgs.ICONS.DOWNLOAD,
                      },
                      on: {
                        click: function ($event) {
                          _vm.popupModalVisible = !_vm.popupModalVisible
                        },
                      },
                    },
                    [_vm._v(" Ladda ner filer ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.applicantId !== null && !_vm.selectedType
            ? _c(
                "a",
                { attrs: { href: _vm.getZipFile(_vm.applicantId) } },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: {
                        "is-rounded": true,
                        icon: _vm.svgs.ICONS.DOWNLOAD,
                      },
                    },
                    [_vm._v(" Ladda ner som ZIP ")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.applicantId !== null && !_vm.selectedType
            ? _c(
                "BaseButton",
                {
                  attrs: { "is-rounded": true, "is-default": true },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.handleGoBack.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Tillbaka")]
              )
            : _vm._e(),
          _vm.applicantId == null
            ? _c(
                "BaseButton",
                {
                  attrs: { "is-rounded": true, "is-default": true },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Stäng")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }