var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "outside-click",
          rawName: "v-outside-click",
          value: _vm.closeDropdown,
          expression: "closeDropdown",
        },
      ],
      staticClass: "app-switcher-dropdown",
      class: { expanded: _vm.isDropdownOpen },
    },
    [
      _c(
        "button",
        {
          staticClass: "selected-option dropdown-link",
          attrs: { type: "button", disabled: !_vm.nonActiveBtns.length },
          on: { click: _vm.toggleDropdown },
        },
        [
          _c("div", { staticClass: "icon-text-container" }, [
            _vm._v(" " + _vm._s(_vm.selectedOption) + " "),
          ]),
          _vm.nonActiveBtns.length
            ? _c("div", { staticClass: "arrow-container" }, [
                _c("div", {
                  staticClass: "arrow",
                  class: { up: _vm.isDropdownOpen, down: !_vm.isDropdownOpen },
                }),
              ])
            : _vm._e(),
        ]
      ),
      _vm.nonActiveBtns.length
        ? _c(
            "div",
            { staticClass: "options-list" },
            _vm._l(_vm.nonActiveBtns, function (nonActiveBtn) {
              return _c(
                "button",
                {
                  key: nonActiveBtn.id,
                  staticClass: "dropdown-link link",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClick(nonActiveBtn.id)
                    },
                  },
                },
                [
                  _vm._v(" " + _vm._s(nonActiveBtn.label) + " "),
                  nonActiveBtn.isLocked
                    ? _c("LockIcon", { staticClass: "lock-icon" })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.ahAccess && _vm.ahAccessModalVisible
        ? _c("SubletInfoModal", {
            on: {
              close: function ($event) {
                _vm.ahAccessModalVisible = false
                _vm.isDropdownOpen = false
              },
            },
          })
        : _vm._e(),
      !_vm.ovPrivateAccess && _vm.ovPrivateAccessModalVisible
        ? _c("OvPrivateInfoModal", {
            on: {
              close: function ($event) {
                _vm.ovPrivateAccessModalVisible = false
                _vm.isDropdownOpen = false
              },
            },
          })
        : _vm._e(),
      !_vm.ovCompanyAccess && _vm.ovCompanyAccessModalVisible
        ? _c("OvCompanyInfoModal", {
            on: {
              close: function ($event) {
                _vm.ovCompanyAccessModalVisible = false
                _vm.isDropdownOpen = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }