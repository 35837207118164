import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import statusTypes from '@/utils/statusTypes';

const state = {
  apps: [],
  ahApps: [],
  removedApps: [],
  filter: '',
  isLoadingApps: true,
  filterCategories: [
    {
      id: 'incoming',
      title: 'Inkommande',
      type: statusTypes.INCOMING,
      selected: false
    },
    {
      id: 'ready',
      title: 'Fatta beslut',
      type: statusTypes.READY,
      selected: false
    },
    {
      id: 'canceled',
      title: 'Avslutade',
      type: statusTypes.CLOSED,
      selected: false
    },
    {
      id: 'approved',
      title: 'Godkända',
      type: statusTypes.APPROVED,
      selected: false
    },
    {
      id: 'rejected',
      title: 'Nekade',
      type: statusTypes.REJECTED,
      selected: false
    }
    // {
    //   id: 'canceled',
    //   title: 'Avbrutna',
    //   type: statusTypes.ABORTED,
    //   selected: true
    // }
  ],
  subletFilterCategories: [
    {
      id: 'incoming',
      title: 'Inkommande',
      type: statusTypes.INCOMING,
      selected: false
    },
    {
      id: 'ready',
      title: 'Fatta beslut',
      type: statusTypes.READY,
      selected: false
    },
    {
      id: 'approved',
      title: 'Pågående',
      type: statusTypes.APPROVED,
      selected: false
    }
  ],
  landlordUsers: [],
  activeDates: [],
  datePickerValue: {
    newCaseFromDate: null,
    newCaseToDate: null,
    swapFromDate: null,
    swapToDate: null
  },
  filterTags: [],
  activeChainId: ''
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations
};
