const statusTypes = {
  INCOMING: 'INCOMING',
  READY: 'READY',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  CLOSED: 'CLOSED',
  ABORTED: 'ABORTED',
  REMOVED: 'REMOVED',
  PENDING_REMOVE: 'PENDING_REMOVE',
  RESTORE: 'RESTORE'
};

export function readableStatusText(landlordStatus) {
  if (landlordStatus === statusTypes.APPROVED) {
    return 'godkänd';
  }
  if (landlordStatus === statusTypes.REJECTED) {
    return 'nekad';
  }
  if (landlordStatus === statusTypes.CLOSED) {
    return 'avslutad';
  }
  if (landlordStatus === statusTypes.PENDING_REMOVE) {
    return 'kommer bli borttagen';
  }
  if (landlordStatus === statusTypes.REMOVED) {
    return 'borttagen';
  }
  return '';
}

export default statusTypes;
