var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("ul", { staticClass: "apartment-info" }, [
      _c("li", [
        _c("b", [_vm._v("Org.nr:")]),
        _vm._v(" " + _vm._s(_vm.company.organizationNumber)),
      ]),
      _vm.company.ceo
        ? _c("li", [
            _c("b", [_vm._v("VD:")]),
            _vm._v(" " + _vm._s(_vm.company.ceo)),
          ])
        : _vm.company.chairman
        ? _c("li", [
            _c("b", [_vm._v("Ordförande:")]),
            _vm._v(" " + _vm._s(_vm.company.chairman) + " "),
          ])
        : _vm.company.boardMember
        ? _c("li", [
            _c("b", [_vm._v("Ledamot:")]),
            _vm._v(" " + _vm._s(_vm.company.boardMember) + " "),
          ])
        : _vm._e(),
      _c("li", [
        _c("b", [_vm._v("SNI-branch:")]),
        _vm._v(" " + _vm._s(_vm.company.sni) + " "),
      ]),
      _c("li", [
        _c("b", [_vm._v("Registreringsdatum:")]),
        _vm._v(
          " " +
            _vm._s(
              new Date(_vm.company.registrationDate).toLocaleDateString("sv-SE")
            ) +
            " "
        ),
      ]),
      _vm.company.employeesAmount
        ? _c("li", [
            _c("b", [_vm._v("Antal anställda:")]),
            _vm._v(" " + _vm._s(_vm.company.employeesAmount) + " "),
          ])
        : _vm._e(),
      _c(
        "li",
        [
          _c(
            "BaseIconText",
            {
              staticClass: "icon-holder",
              attrs: {
                size: "smaller",
                "icon-url": _vm.getIcon(_vm.company.moms),
              },
            },
            [_vm._v(" Moms ")]
          ),
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "BaseIconText",
            {
              staticClass: "icon-holder",
              attrs: {
                size: "smaller",
                "icon-url": _vm.getIcon(_vm.company.fSkatt),
              },
            },
            [_vm._v(" F-skatt ")]
          ),
        ],
        1
      ),
      _c(
        "li",
        [
          _c(
            "BaseIconText",
            {
              staticClass: "icon-holder",
              attrs: {
                size: "smaller",
                "icon-url": _vm.getIcon(_vm.company.arbetsgivaravgift),
              },
            },
            [_vm._v(" Arbetsgivaravgift ")]
          ),
        ],
        1
      ),
    ]),
    _c("h4", [
      _c("b", [_vm._v("Resultaträkning " + _vm._s(_vm.firstAccounting.year))]),
    ]),
    _c("ul", { staticClass: "apartment-info" }, [
      _c("li", [
        _c("b", [_vm._v("Omsättning:")]),
        _vm._v(" " + _vm._s(_vm.firstAccounting.revenue) + " kr"),
      ]),
      _c("li", [
        _c("b", [_vm._v("Resultat:")]),
        _vm._v(" " + _vm._s(_vm.firstAccounting.netProfit) + " kr"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }