var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("DefaultLineChart", {
    attrs: { data: _vm.data, loading: _vm.loading, options: _vm.chartOptions },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }