var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ApplicationsRow",
    { attrs: { "two-columns": "" } },
    [
      _c(
        "ApplicationCard",
        { attrs: { "dark-bg": _vm.activeCurrentTenant.index === 1 } },
        [
          _c(
            "template",
            { slot: "top-content" },
            [
              _c("h4", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.currentTenants.length > 1
                        ? _vm.activeCurrentTenant.index === 0
                          ? "Överlåtare 1"
                          : "Överlåtare 2"
                        : "Överlåtaren"
                    ) +
                    " "
                ),
              ]),
              _c("PersonCard", {
                attrs: { name: _vm.activeCurrentTenant.name },
              }),
              _vm.currentTenants.length > 1
                ? _c("MemberSelector", {
                    attrs: {
                      items: _vm.currentTenants.map((tenant, index) => ({
                        ...tenant,
                        label: tenant.name,
                      })),
                      "active-index": _vm.activeCurrentTenantIndex,
                    },
                    on: { selectItemIndex: _vm.selectCurrentTenantIndex },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "h3",
            {
              key: _vm.activeCurrentTenant.name,
              staticClass: "name capitalize-first",
            },
            [_vm._v(" " + _vm._s(_vm.activeCurrentTenant.name) + " ")]
          ),
          _c("ul", { staticClass: "apartment-info" }, [
            _c("li", [
              _c("b", [_vm._v("Personnr.:")]),
              _vm._v(" " + _vm._s(_vm.activeCurrentTenant.ssn)),
            ]),
            _c("li", [
              _c("b", [_vm._v("E-post:")]),
              _vm._v(" " + _vm._s(_vm.activeCurrentTenant.email)),
            ]),
            _c("li", [
              _c("b", [_vm._v("Telefonnr.:")]),
              _vm._v(" " + _vm._s(_vm.activeCurrentTenant.phone) + " "),
            ]),
            _c("li", [
              _c("b", [_vm._v("Adress:")]),
              _vm._v(" " + _vm._s(_vm.activeCurrentTenant.address)),
            ]),
            _c("li", [
              _c("b", [_vm._v("Postnr.:")]),
              _vm._v(" " + _vm._s(_vm.activeCurrentTenant.postalCode)),
            ]),
            _c("li", [
              _c("b", [_vm._v("Ort:")]),
              _vm._v(" " + _vm._s(_vm.activeCurrentTenant.city)),
            ]),
          ]),
          _c("hr", { staticClass: "divider" }),
          _c("OvApplicationCheckboxes", {
            attrs: {
              files: _vm.application.files,
              application: _vm.application,
              "application-id": _vm.application.id,
              "checked-files": _vm.application.checkedFiles,
              "other-checkboxes": _vm.otherCheckboxes,
              "application-status": _vm.application.landlordStatus,
            },
            on: {
              openFile: function ($event) {
                return _vm.$emit("openFile", $event)
              },
              checksChange: function ($event) {
                return _vm.$emit("checksChange", $event)
              },
            },
          }),
          _c("br"),
          _c("div", { staticClass: "card-content" }, [
            _c("h4", [_vm._v("Skäl")]),
            _c("p", [_vm._v(_vm._s(_vm.getReasonText))]),
          ]),
          _c("br"),
          _c("div", { staticClass: "card-content" }, [
            _c("h4", [_vm._v("Anledning")]),
            _c("p", [_vm._v(_vm._s(_vm.application.reason.text))]),
          ]),
        ],
        2
      ),
      _c(
        "ApplicationCard",
        { attrs: { "dark-bg": _vm.activeSecondaryTenant.index === 1 } },
        [
          _c(
            "template",
            { slot: "top-content" },
            [
              _c("h4", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.secondaryTenants.length > 1
                        ? _vm.activeSecondaryTenant.index === 0
                          ? "Övertagare 1"
                          : "Övertagare 2"
                        : "Övertagaren"
                    ) +
                    " "
                ),
              ]),
              _c("PersonCard", {
                attrs: { name: _vm.activeSecondaryTenant.name },
              }),
              _vm.secondaryTenants.length > 1
                ? _c("MemberSelector", {
                    attrs: {
                      items: _vm.secondaryTenants.map((tenant, index) => ({
                        ...tenant,
                        label: tenant.name,
                      })),
                      "active-index": _vm.activeCurrentTenantIndex,
                    },
                    on: { selectItemIndex: _vm.selectSecondaryTenantIndex },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "h3",
            {
              key: _vm.activeSecondaryTenant.name,
              staticClass: "name capitalize-first",
            },
            [_vm._v(" " + _vm._s(_vm.activeSecondaryTenant.name) + " ")]
          ),
          _c("ul", { staticClass: "apartment-info" }, [
            _c("li", [
              _c("b", [_vm._v("Personnr.:")]),
              _vm._v(" " + _vm._s(_vm.activeSecondaryTenant.ssn)),
            ]),
            _c("li", [
              _c("b", [_vm._v("E-post:")]),
              _vm._v(" " + _vm._s(_vm.activeSecondaryTenant.email)),
            ]),
            _c("li", [
              _c("b", [_vm._v("Telefonnr.:")]),
              _vm._v(" " + _vm._s(_vm.activeSecondaryTenant.phone) + " "),
            ]),
            _c("li", [
              _c("b", [_vm._v("Adress:")]),
              _vm._v(" " + _vm._s(_vm.activeSecondaryTenant.address)),
            ]),
            _c("li", [
              _c("b", [_vm._v("Postnr.:")]),
              _vm._v(" " + _vm._s(_vm.activeSecondaryTenant.postalCode)),
            ]),
            _c("li", [
              _c("b", [_vm._v("Ort:")]),
              _vm._v(" " + _vm._s(_vm.activeSecondaryTenant.city)),
            ]),
          ]),
          _c("hr", { staticClass: "divider" }),
          _c("OvApplicationCheckboxes", {
            attrs: {
              files: _vm.application.files,
              application: _vm.application,
              "application-id": _vm.application.id,
              "checked-files": _vm.application.checkedFiles,
              "application-status": _vm.application.landlordStatus,
              "is-transferor": false,
            },
            on: {
              openFile: function ($event) {
                return _vm.$emit("openFile", $event)
              },
              checksChange: function ($event) {
                return _vm.$emit("checksChange", $event)
              },
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }