// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets/svgs/icons/chevron-down.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.select-wrapper[data-v-73c1c477] {\n  position: relative;\n  border-radius: 10px;\n  overflow: hidden;\n  display: inline-block;\n}\n.select-wrapper.hidden[data-v-73c1c477] {\n  opacity: 0;\n  visibility: hidden;\n  pointer-events: none;\n}\nselect[data-v-73c1c477] {\n  font-family: var(--font-averta);\n  cursor: pointer;\n  background-color: transparent;\n  color: var(--color-grey);\n  font-size: 1.2rem;\n  line-height: 1.5;\n  font-weight: 600;\n  border: none;\n  position: relative;\n  outline: 0;\n  width: 100%;\n  padding: 0.4rem 0rem 0.4rem 2.1rem;\n  -webkit-appearance: none;\n     -moz-appearance: none;\n          appearance: none;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat left center;\n  background-size: 2rem;\n}\n", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
