var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "person" }, [
    _c("div", { staticClass: "person-info-wrapper" }, [
      _c("div", [
        _c("h3", { staticClass: "name capitalize-first" }, [
          _vm._v(" " + _vm._s(_vm.name) + " "),
        ]),
        _vm.personType
          ? _c("small", { staticClass: "name-small" }, [
              _vm._v(" (" + _vm._s(_vm.personType) + ")"),
            ])
          : _vm._e(),
        _c("p", { staticClass: "address" }, [
          _vm._v(" " + _vm._s(_vm.address) + " "),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "pointer" }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }