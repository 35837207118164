import { APP_NAMES } from '@/utils/constants';
import statusTypes from '@/utils/statusTypes';

function filterRecursive(items, query) {
  if (items === null) {
    return false;
  }

  if (Array.isArray(items)) {
    return items.filter(item => filterRecursive(item, query)).length;
  } else if (typeof items === 'object') {
    return Object.values(items).filter(val => filterRecursive(val, query))
      .length;
  } else if (typeof items === 'string') {
    const itemLowerCase = items.toLowerCase();
    const date = new Date(Number(itemLowerCase));
    if (date instanceof Date && !isNaN(date) && date.getFullYear() !== 1970) {
      return date.toISOString().slice(0, 10).includes(query);
    } else {
      return itemLowerCase.toLowerCase().includes(query);
    }
  }

  return false;
}

function filterDate(dateVal, startVal, endVal) {
  const startDate = new Date(startVal);
  const endDate = new Date(endVal);
  const appDate = new Date(dateVal);
  const noStartDate = startVal == null;
  const noEndDate = endVal == null;
  endDate.setHours(23, 59, 59, 999);

  if (noStartDate && noEndDate) {
    return true;
  }
  if (noStartDate) {
    return appDate <= endDate;
  }
  if (noEndDate) {
    return appDate >= startDate;
  }
  return appDate >= startDate && appDate <= endDate;
}

function activeCategories(state, getters, rootState) {
  if (rootState.app.activeApp === APP_NAMES.ANDRAHAND) {
    return state.subletFilterCategories;
  }
  return state.filterCategories;
}

function filterCategories(state, getters) {
  return getters.activeCategories
    .filter(cat => cat.selected)
    .map(cat => cat.type);
}

function filterTagsIds(state) {
  return state.filterTags.filter(tag => tag.selected).map(tag => tag.id);
}

function filterLandlordUsers(state) {
  return state.landlordUsers.filter(user => user.selected).map(user => user.id);
}

function filterInput(filter, app) {
  if (filter.length > 0) {
    const query = filter;
    return filterRecursive(app, query.toLowerCase());
  }
  return app;
}

function filterActiveTags(applicationTags, activeTags) {
  if (!activeTags.length) {
    return true;
  }
  return !!activeTags.filter(tagId => applicationTags.includes(tagId)).length;
}

function filterAppsByKey({ state, getters }, key, isAh = false) {
  const activeCategories = filterCategories(state, getters);
  const activeTags = filterTagsIds(state, getters);
  const activeUsers = filterLandlordUsers(state);
  const { newCaseFromDate, newCaseToDate, swapFromDate, swapToDate } =
    state.datePickerValue;

  if (!state[key]?.length) {
    return [];
  }

  let statusKey = 'status';

  if (isAh) {
    statusKey = 'landlordStatus';
  }

  return state[key].filter(app => {
    const createdDate = isAh ? app.sentToHostAt : parseInt(app.sentToHost);
    const assignedLandlordUser = isAh
      ? app.assignedLandlordUserId
      : app.assignedLandlordUser?.id;
    return (
      filterActiveTags(app.tags, activeTags) &&
      filterDate(createdDate, newCaseFromDate, newCaseToDate) &&
      filterDate(app.swapDate, swapFromDate, swapToDate) &&
      (activeCategories.length === 0 ||
        activeCategories.includes(app[statusKey])) &&
      (activeUsers.length === 0 ||
        activeUsers.includes(assignedLandlordUser || null)) &&
      filterInput(state.filter, app)
    );
  });
}

function filteredApps(state, getters) {
  return filterAppsByKey({ state, getters }, 'apps');
}

function filteredRemovedApps(state, getters) {
  return filterAppsByKey({ state, getters }, 'removedApps');
}

function filteredAhApps(state, getters) {
  return filterAppsByKey({ state, getters }, 'ahApps', true);
}

function activeFilters(state, getters) {
  const { newCaseFromDate, newCaseToDate, swapFromDate, swapToDate } =
    state.datePickerValue;
  return [
    !!state.filterTags.find(tag => tag.selected),
    !!newCaseFromDate || !!newCaseToDate,
    !!swapFromDate || !!swapToDate,
    !!getters.activeCategories.find(tag => tag.selected),
    !!state.landlordUsers.find(user => user.selected)
  ].filter(val => val).length;
}

function ahAppsChains(state, getters) {
  const chains = {};
  state.ahApps
    .filter(app => app.latestVersion === 1)
    .forEach(app => {
      const pendingStatuses = [statusTypes.INCOMING, statusTypes.READY];
      const parentApp = state.ahApps.find(x => x.id === app.parentId);

      if (
        app.parentId &&
        pendingStatuses.includes(app.landlordStatus) &&
        !getters.ahArchivedStatuses.includes(parentApp?.landlordStatus)
      ) {
        chains[app.id] = app.parentId;
        chains[app.parentId] = app.id;
      }
    });
  return chains;
}

function ahPendingStatuses() {
  return [statusTypes.INCOMING, statusTypes.READY, statusTypes.APPROVED];
}
function ahArchivedStatuses() {
  return [
    statusTypes.CLOSED,
    statusTypes.REJECTED,
    statusTypes.ABORTED,
    statusTypes.REMOVED,
    statusTypes.PENDING_REMOVE
  ];
}

export default {
  filteredApps,
  filteredRemovedApps,
  filteredAhApps,
  activeCategories,
  activeFilters,
  ahAppsChains,
  ahPendingStatuses,
  ahArchivedStatuses
};
