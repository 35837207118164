var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.isLoading && _vm.errorModalVisible
    ? _c("ErrorModal", {
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      })
    : _vm.isLoading
    ? _c(
        "div",
        { staticClass: "loading-wrapper" },
        [_c("BaseLoadingSpinner", { attrs: { "with-overlay": false } })],
        1
      )
    : _vm.application && _vm.documentsCollections
    ? _c(
        "ApplicationContainerModal",
        _vm._g(
          {
            directives: [
              {
                name: "esc-click",
                rawName: "v-esc-click",
                value: _vm.handleEscClick,
                expression: "handleEscClick",
              },
            ],
          },
          _vm.$listeners
        ),
        [
          _c(
            "template",
            { slot: "modals" },
            [
              _vm.currentModal === "NOTES"
                ? _c("NotesModal", {
                    attrs: {
                      "unread-notes": _vm.application.unreadNotes,
                      "application-id": _vm.application.id,
                      "read-only-mode": _vm.hasRemovalTypeStatus,
                    },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      activeTabChange: _vm.handleTabClick,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "DOCUMENTS"
                ? _c("DocumentsModal", {
                    attrs: {
                      "application-id": _vm.application.id,
                      parts: _vm.application.parts,
                      "documents-collections": _vm.documentsCollections,
                      "is-loading": !_vm.documentsCollections,
                      "selected-applicant-id": _vm.selectedApplicantId,
                      "selected-type": _vm.selectedDocumentType,
                      "has-scrive-document": !!_vm.application.scriveId,
                      "initial-member-index": _vm.initialMemberIndex,
                    },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                        _vm.selectedApplicantId = null
                        _vm.selectedDocumentType = null
                        _vm.initialMemberIndex = -1
                      },
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "REJECT"
                ? _c("RejectModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onRejected: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "ABORT"
                ? _c("AbortModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onAborted: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "RESTORE"
                ? _c("RestoreModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onRestored: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "CANCEL_REMOVAL"
                ? _c("CancelRemovalModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onCancelledDeletion: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "USER_NOT_ASSIGNED"
                ? _c("NotAssignedModal", {
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      confirm: function ($event) {
                        _vm.currentModal = "REMOVE"
                      },
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "REMOVE"
                ? _c("RemoveModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onRemoved: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "CHANGE_TO_REJECT"
                ? _c("ChangeToRejectModal", {
                    attrs: { "application-id": _vm.application.id },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onRejected: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "APPROVE"
                ? _c("ApprovalModal", {
                    attrs: {
                      "application-id": _vm.application.id,
                      parts: _vm.application.parts,
                    },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onApproved: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.currentModal === "CHANGE_TO_APPROVE"
                ? _c("ChangeToApprovalModal", {
                    attrs: {
                      "application-id": _vm.application.id,
                      parts: _vm.application.parts,
                    },
                    on: {
                      close: function ($event) {
                        _vm.currentModal = null
                      },
                      onApproved: _vm.leaveApplication,
                    },
                  })
                : _vm._e(),
              _vm.selectedComment !== null
                ? _c("CommentModal", {
                    attrs: {
                      name: _vm.selectedComment.name,
                      "document-type": _vm.selectedComment.comment.documentType,
                      content: _vm.selectedComment.comment.content,
                    },
                    on: {
                      close: function ($event) {
                        _vm.selectedComment = null
                      },
                    },
                  })
                : _vm._e(),
              _vm.selectedPartInfo !== null
                ? _c("PartInfoModal", {
                    attrs: {
                      position: _vm.selectedPartInfoModalPosition,
                      content: _vm.selectedPartInfo,
                      "application-id": _vm.application.id,
                    },
                    on: {
                      close: function ($event) {
                        _vm.selectedPartInfo = null
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "template",
            { slot: "header" },
            [
              _c("TabHeader", {
                attrs: {
                  "all-disabled": _vm.isAnimatingModalSize,
                  title: _vm.application.type,
                  subtitle: _vm.tabHeaderSubtitle,
                  "application-status": _vm.application.status,
                  "show-help-button": _vm.showHelpButton,
                  tabs: [
                    {
                      title: "Bytesparter",
                      active: _vm.activeTab === 1,
                      tabLink: 1,
                    },
                    {
                      title: "Visa bytesträd",
                      active: _vm.activeTab === 2,
                      disabled: _vm.isIncoming || _vm.isRemoved,
                      tabLink: 2,
                    },
                    {
                      title: "Visa karta",
                      active: _vm.activeTab === 3,
                      disabled: _vm.isIncoming || _vm.isRemoved,
                      tabLink: 3,
                    },
                  ],
                },
                on: {
                  helpButtonClicked: _vm.handleHelpButtonClicked,
                  tabClick: _vm.setTab,
                },
              }),
            ],
            1
          ),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.activeTab === 1
                ? _c(
                    "div",
                    {
                      staticClass: "applications-row",
                      class: {
                        "applications-row-2-columns":
                          _vm.application.parts.length === 2,
                      },
                    },
                    _vm._l(_vm.application.parts, function (part, idx) {
                      return _c(
                        "div",
                        { key: idx, staticClass: "application" },
                        [
                          _c("div", { staticClass: "person" }, [
                            _c("div", { staticClass: "person-info-wrapper" }, [
                              _c("div", [
                                _c(
                                  "h3",
                                  { staticClass: "name capitalize-first" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          part.members.length > 1
                                            ? part.members
                                                .map(
                                                  (member) =>
                                                    member.name
                                                      .trim()
                                                      .split(/\s+/)[0] || ""
                                                )
                                                .join(" & ")
                                            : part.members[0].name
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("p", { staticClass: "address" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.buildAddressStr(part)) +
                                      " "
                                  ),
                                ]),
                              ]),
                            ]),
                            !_vm.isIncoming && !_vm.isRemoved
                              ? _c(
                                  "div",
                                  { staticClass: "pointer" },
                                  [
                                    _c("BaseImage", {
                                      staticClass: "icon",
                                      attrs: { url: _vm.svgs.ICONS.CONTACT },
                                      on: {
                                        click: (e) => {
                                          const x = e.clientX
                                          const y = e.clientY + 20
                                          _vm.openPartInfoModal(x, y, part)
                                        },
                                      },
                                    }),
                                    _vm.isFromLb(_vm.application)
                                      ? _c("BaseImage", {
                                          key: `${_vm.application.id}-lb`,
                                          staticClass: "lb-icon",
                                          attrs: {
                                            url: _vm.svgs.ICONS.LB_LOGO,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.activeTab === 1
                ? _c(
                    "div",
                    {
                      staticClass: "applications-row",
                      class: {
                        "applications-row-2-columns":
                          _vm.application.parts.length === 2,
                      },
                    },
                    _vm._l(_vm.application.parts, function (part, idx) {
                      return _c(
                        "div",
                        { key: idx, staticClass: "application" },
                        [
                          part.members.length > 1
                            ? _c(
                                "div",
                                { staticClass: "member-selector" },
                                _vm._l(
                                  part.members,
                                  function (member, memberIdx) {
                                    return _c(
                                      "a",
                                      {
                                        key: memberIdx,
                                        class: {
                                          selected:
                                            _vm.getSelectedMemberIndex(part) ===
                                            memberIdx,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectMemberIndex(
                                              part,
                                              memberIdx
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getShortName(member.name)
                                            ) +
                                            " "
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.activeTab === 1
                ? _c(
                    "div",
                    {
                      staticClass: "applications-row",
                      class: {
                        "applications-row-2-columns":
                          _vm.application.parts.length === 2,
                      },
                    },
                    _vm._l(_vm.application.parts, function (part, idx) {
                      return _c(
                        "div",
                        { key: idx, staticClass: "application" },
                        [
                          _c(
                            "GreyCard",
                            {
                              staticClass: "application-card",
                              class: {
                                memberOneSelected:
                                  _vm.getSelectedMemberIndex(part) === 0,
                                memberTwoSelected:
                                  _vm.getSelectedMemberIndex(part) === 1,
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "card-content" },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        mode: "out-in",
                                        name: "slideFade",
                                      },
                                    },
                                    [
                                      _c("SwapCardContent", {
                                        key: _vm.getSelectedMemberIndex(part),
                                        attrs: {
                                          part: part,
                                          "show-tooltip":
                                            _vm.activeTab === 1 && idx === 0,
                                          "application-status":
                                            _vm.application.status,
                                          "documents-collections":
                                            _vm.documentsCollections,
                                          "selected-member-idx":
                                            _vm.getSelectedMemberIndex(part),
                                          "is-disabled":
                                            _vm.hasRemovalTypeStatus,
                                        },
                                        on: {
                                          checksChange: function ($event) {
                                            return _vm.updateCheckedMember(
                                              idx,
                                              $event.checkboxType,
                                              $event.checked
                                            )
                                          },
                                          selectedComment: function ($event) {
                                            _vm.handleSelectedComment({
                                              comment: $event,
                                              name: part.members[
                                                _vm.getSelectedMemberIndex(part)
                                              ].name,
                                            })
                                          },
                                          openFile: function ($event) {
                                            return _vm.openDocumentModal(
                                              part,
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.isIncoming && !_vm.isRemoved
                                ? _c("div", { staticClass: "card-content" }, [
                                    _c("h3", [_vm._v("Scenario")]),
                                    _c("p", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.formatScenario(
                                            part.members[0].scenario
                                          )
                                        ),
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                              !_vm.isIncoming && !_vm.isRemoved
                                ? _c("div", { staticClass: "card-content" }, [
                                    _c("h3", [_vm._v("Beaktansvärt skäl")]),
                                    _c("p", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            part.members[0].swapReason || "-"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                              _c("div", { staticClass: "card-content" }, [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "landlord-title",
                                    attrs: { title: "Ansluten hyresvärd" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(part.landlord.name) + " "
                                    ),
                                    part.landlord.id
                                      ? _c(
                                          "svg",
                                          {
                                            staticClass: "landlord-badge",
                                            attrs: {
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                              version: "1.1",
                                              viewBox: "0 0 24 24",
                                            },
                                          },
                                          [
                                            _c("path", {
                                              attrs: {
                                                d: "M22.5 12.5c0-1.58-.875-2.95-2.148-3.6.154-.435.238-.905.238-1.4 0-2.21-1.71-3.998-3.818-3.998-.47 0-.92.084-1.336.25C14.818 2.415 13.51 1.5 12 1.5s-2.816.917-3.437 2.25c-.415-.165-.866-.25-1.336-.25-2.11 0-3.818 1.79-3.818 4 0 .494.083.964.237 1.4-1.272.65-2.147 2.018-2.147 3.6 0 1.495.782 2.798 1.942 3.486-.02.17-.032.34-.032.514 0 2.21 1.708 4 3.818 4 .47 0 .92-.086 1.335-.25.62 1.334 1.926 2.25 3.437 2.25 1.512 0 2.818-.916 3.437-2.25.415.163.865.248 1.336.248 2.11 0 3.818-1.79 3.818-4 0-.174-.012-.344-.033-.513 1.158-.687 1.943-1.99 1.943-3.484zm-6.616-3.334l-4.334 6.5c-.145.217-.382.334-.625.334-.143 0-.288-.04-.416-.126l-.115-.094-2.415-2.415c-.293-.293-.293-.768 0-1.06s.768-.294 1.06 0l1.77 1.767 3.825-5.74c.23-.345.696-.436 1.04-.207.346.23.44.696.21 1.04z",
                                                fill: "#1da1f2",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _c("p", [
                                  part.landlord.phone
                                    ? _c("span", [
                                        _vm._v(
                                          _vm._s(part.landlord.phone) + " "
                                        ),
                                        _c("br"),
                                      ])
                                    : _vm._e(),
                                  part.landlord.email
                                    ? _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(part.landlord.email) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]),
                              ]),
                              part.members[_vm.getSelectedMemberIndex(part)]
                                .landlordDocuments.length > 0
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "pdf-link",
                                      attrs: {
                                        href: `${
                                          _vm.$http.apiEndpoint
                                        }/documents/landlord/${
                                          _vm.currentApplicationId
                                        }/${
                                          part.members[
                                            _vm.getSelectedMemberIndex(part)
                                          ].landlordDocuments[0].id
                                        }`,
                                      },
                                    },
                                    [_vm._v(" Sammanfattning - PDF")]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "inspectionApproved-container" },
                            [
                              _c(
                                "SwapApplicationListCheck",
                                {
                                  attrs: {
                                    part: part,
                                    "is-disabled": _vm.hasRemovalTypeStatus,
                                    "hide-icon": true,
                                    "disable-tooltip": true,
                                    "checkbox-name": "INSPECTION_APPROVED",
                                    documents:
                                      _vm.documentsCollections[
                                        _vm.getFullIdPath(
                                          part,
                                          _vm.documentsCollections
                                        )
                                      ],
                                    "application-status":
                                      _vm.application.status,
                                  },
                                  on: {
                                    checksChange: function ($event) {
                                      return _vm.updateCheckedMember(
                                        idx,
                                        "INSPECTION_APPROVED",
                                        $event
                                      )
                                    },
                                    selectedComment: function ($event) {
                                      _vm.handleSelectedComment({
                                        comment: $event,
                                        name: part.members[
                                          _vm.getSelectedMemberIndex(part)
                                        ].name,
                                      })
                                    },
                                    openFile: function ($event) {
                                      return _vm.openDocumentModal(
                                        part,
                                        "INSPECTION_APPROVED"
                                      )
                                    },
                                  },
                                },
                                [_vm._v(" Besiktning godkänd ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm.activeTab === 2
                ? _c("SwapTree", {
                    attrs: { "application-id": _vm.application.id },
                  })
                : _c("Map", {
                    attrs: { "application-id": _vm.application.id },
                  }),
            ],
            1
          ),
          _c("template", { slot: "footer" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _vm.activeTab === 1
                  ? _c("Tooltip", {
                      attrs: {
                        step: "assignCaseManager",
                        position: "bottom-left",
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "assign-landlord-users-container" },
                  [
                    _c(
                      "BaseItemsList",
                      {
                        class: {
                          "no-pointer-events": _vm.hasRemovalTypeStatus,
                        },
                        attrs: {
                          items: _vm.landlordUsersWithUnAssign.filter(
                            (x) => !x.inactivatedAt
                          ),
                          "show-contact": true,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.assignLandlordUser($event)
                          },
                        },
                      },
                      [
                        !_vm.application.assignedLandlordUser
                          ? _c(
                              "BaseIconText",
                              {
                                staticClass: "assign-button",
                                attrs: {
                                  "icon-url": _vm.svgs.ICONS.ADMINISTRATOR,
                                  size: "larger",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.hasRemovalTypeStatus
                                        ? "Ej tilldelad"
                                        : "Tilldela handläggare 1"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c("UserIcon", {
                              attrs: {
                                "first-name":
                                  _vm.application.assignedLandlordUser
                                    .firstName,
                                "last-name":
                                  _vm.application.assignedLandlordUser.lastName,
                                "full-name-visible": true,
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "BaseItemsList",
                      {
                        class: {
                          "no-pointer-events": _vm.hasRemovalTypeStatus,
                        },
                        attrs: {
                          items: _vm.secondaryLandlordUsersWithUnAssign.filter(
                            (x) => !x.inactivatedAt
                          ),
                          "show-contact": true,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.assignSecondaryLandlordUser($event)
                          },
                        },
                      },
                      [
                        !_vm.application.secondaryAssignedLandlordUser
                          ? _c(
                              "BaseIconText",
                              {
                                staticClass: "assign-button",
                                attrs: {
                                  "icon-url": _vm.svgs.ICONS.ADMINISTRATOR,
                                  size: "larger",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.hasRemovalTypeStatus
                                        ? "Ej tilldelad"
                                        : "Tilldela handläggare 2"
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _c("UserIcon", {
                              attrs: {
                                "first-name":
                                  _vm.application.secondaryAssignedLandlordUser
                                    .firstName,
                                "last-name":
                                  _vm.application.secondaryAssignedLandlordUser
                                    .lastName,
                                "full-name-visible": true,
                              },
                            }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    !_vm.isIncoming
                      ? _c(
                          "button",
                          {
                            staticClass: "simple-button notes-button",
                            on: {
                              click: function ($event) {
                                _vm.currentModal = "NOTES"
                              },
                            },
                          },
                          [
                            _c(
                              "BaseIconText",
                              {
                                attrs: {
                                  "icon-url": _vm.svgs.ICONS.NOTEBOOK,
                                  size: "larger",
                                },
                              },
                              [
                                _vm.anyUnreadNotes
                                  ? _c("NotificationSymbol", {
                                      attrs: {
                                        slot: "icon",
                                        "border-color": "#f1f2f6",
                                      },
                                      slot: "icon",
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  _vm._s(
                                    _vm.isSkandia
                                      ? "Chatt och logg"
                                      : "Chatt och noteringar"
                                  ) + " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.activeTab === 1
                      ? _c("Tooltip", {
                          attrs: {
                            step: "notes",
                            position: "bottom-left",
                            "btn-text": "Tack, jag förstår",
                            "show-arrow": false,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                !_vm.isIncoming && !_vm.hasRemovalTypeStatus
                  ? _c(
                      "button",
                      {
                        staticClass: "simple-button files-button",
                        on: {
                          click: function ($event) {
                            _vm.currentModal = "DOCUMENTS"
                          },
                        },
                      },
                      [
                        _c(
                          "BaseIconText",
                          {
                            attrs: {
                              "icon-url": _vm.svgs.ICONS.ATTACHMENT,
                              size: "larger",
                            },
                          },
                          [
                            _vm._v(
                              "Visa " + _vm._s(_vm.documentCount) + " filer "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "right" },
              [
                _vm.isPendingRemoval
                  ? _c(
                      "div",
                      [
                        _c(
                          "BaseButton",
                          {
                            staticClass: "mr-15",
                            attrs: { "is-rounded": true, "is-dark": true },
                            on: {
                              click: function ($event) {
                                _vm.currentModal = "CANCEL_REMOVAL"
                              },
                            },
                          },
                          [_vm._v(" Avbryt radering ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isIncoming && !_vm.hasRemovalTypeStatus
                  ? _c(
                      "div",
                      { staticClass: "popupModal-container mr-15" },
                      [
                        _vm.popupModalVisible
                          ? _c("PopupModal", {
                              attrs: { items: _vm.itemsInPopupModal },
                              on: {
                                close: function ($event) {
                                  _vm.popupModalVisible = false
                                },
                                click: _vm.handlePopupClick,
                              },
                            })
                          : _vm._e(),
                        _c(
                          "button",
                          {
                            staticClass: "simple-button notes-button",
                            on: {
                              click: function ($event) {
                                _vm.popupModalVisible = !_vm.popupModalVisible
                              },
                            },
                          },
                          [
                            _c(
                              "BaseIconText",
                              { attrs: { "icon-url": _vm.svgs.ICONS.MORE } },
                              [_vm._v(" Fler val")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.application.status === _vm.statusTypes.READY
                  ? [
                      _c(
                        "BaseButton",
                        {
                          staticClass: "mr-15",
                          attrs: {
                            "is-warning": true,
                            "is-rounded": true,
                            icon: _vm.svgs.ICONS.ERROR_WHITE,
                          },
                          on: {
                            click: function ($event) {
                              _vm.currentModal = "REJECT"
                            },
                          },
                        },
                        [_vm._v(" Neka byte ")]
                      ),
                      _c(
                        "BaseButton",
                        {
                          attrs: {
                            "is-rounded": true,
                            icon: _vm.svgs.ICONS.CHECK_WHITE,
                          },
                          on: {
                            click: function ($event) {
                              _vm.currentModal = "APPROVE"
                            },
                          },
                        },
                        [_vm._v(" Godkänn byte ")]
                      ),
                    ]
                  : [
                      _vm.application.status === _vm.statusTypes.APPROVED ||
                      _vm.application.status === _vm.statusTypes.ABORTED
                        ? _c(
                            "BaseButton",
                            {
                              staticClass: "mr-15",
                              attrs: {
                                "is-warning": true,
                                "is-rounded": true,
                                icon: _vm.svgs.ICONS.ERROR_WHITE,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.currentModal = "CHANGE_TO_REJECT"
                                },
                              },
                            },
                            [_vm._v(" Ändra till nekad ")]
                          )
                        : _vm._e(),
                      _vm.application.status === _vm.statusTypes.REJECTED ||
                      _vm.application.status === _vm.statusTypes.ABORTED
                        ? _c(
                            "BaseButton",
                            {
                              attrs: {
                                "is-rounded": true,
                                icon: _vm.svgs.ICONS.CHECK_WHITE,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.currentModal = "CHANGE_TO_APPROVE"
                                },
                              },
                            },
                            [_vm._v(" Ändra till godkänd ")]
                          )
                        : _vm._e(),
                    ],
                _c("RejectReasonModal", {
                  attrs: {
                    "application-id": _vm.currentApplicationId,
                    "application-status": _vm.application.status,
                  },
                }),
              ],
              2
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }