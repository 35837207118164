var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "filter-btn",
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c("img", {
        attrs: {
          src: require("../assets/svgs/icons/sliders.svg"),
          alt: "filter ikon",
        },
      }),
      _c("p", [
        _vm._v(
          "Filtrera " +
            _vm._s(_vm.activeFilters ? `(${_vm.activeFilters})` : "")
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }