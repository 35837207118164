var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ApplicationCard",
    {
      attrs: {
        type: _vm.reasonTypeTitle,
        to: _vm.getPath,
        status: _vm.application.landlordStatus,
        subtitle: _vm.subtitle(_vm.application.landlordStatus),
        "subtitle-class": _vm.subtitleClass(_vm.application.landlordStatus),
        "is-new": _vm.isNew,
        "full-name": _vm.fullName,
        "last-name": _vm.lastName,
        "status-date": _vm.statusDate,
        "unread-notes": _vm.application?.unreadNotes,
        "closed-statuses": _vm.closedStatuses,
        "application-id": `${_vm.application.id}`,
        "application-tags": _vm.application.tags,
        "show-tag-button": _vm.showTagButton,
        "enable-link": _vm.enableLink,
      },
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "application-card-info-container" }, [
          _c(
            "div",
            { staticClass: "application-card-info" },
            [
              _vm.isCompanyApplication
                ? [
                    _c("p", [
                      _c("span", { staticClass: "capitalize-first" }, [
                        _vm._v("Överlåtaren"),
                      ]),
                      _c("span", { staticClass: "light" }, [
                        _vm._v(
                          _vm._s(
                            _vm.application.currentTenants[0]
                              ?.organizationNumber
                          ) +
                            " (" +
                            _vm._s(_vm.application.currentTenants[0]?.name) +
                            ")"
                        ),
                      ]),
                    ]),
                    _c("p", [
                      _c("span", { staticClass: "capitalize-first" }, [
                        _vm._v("Lokalens adress"),
                      ]),
                      _c("span", { staticClass: "light" }, [
                        _vm._v(
                          _vm._s(_vm.application.apartment.address) +
                            ", " +
                            _vm._s(_vm.application.apartment.postalCode) +
                            " " +
                            _vm._s(_vm.application.apartment.city)
                        ),
                      ]),
                    ]),
                    _c("p", [
                      _c("span", { staticClass: "capitalize-first" }, [
                        _vm._v("Hyreskontraktnr."),
                      ]),
                      _c("span", { staticClass: "light" }, [
                        _vm._v(
                          _vm._s(_vm.application.apartment.contractNumber)
                        ),
                      ]),
                    ]),
                    _c("p", [
                      _c("span", { staticClass: "capitalize-first" }, [
                        _vm._v("Övertagaren"),
                      ]),
                      _c("span", { staticClass: "light" }, [
                        _vm._v(
                          _vm._s(
                            _vm.application.secondaryTenants[0]
                              ?.organizationNumber
                          ) +
                            " (" +
                            _vm._s(_vm.application.secondaryTenants[0]?.name) +
                            ")"
                        ),
                      ]),
                    ]),
                  ]
                : [
                    _c("p", [
                      _c("span", { staticClass: "capitalize-first" }, [
                        _vm._v(_vm._s(_vm.currentTenantsNameGroup)),
                      ]),
                      _c("span", { staticClass: "light" }, [
                        _vm._v(_vm._s(_vm.application.apartment?.address)),
                      ]),
                    ]),
                    _vm.application.secondaryTenants[0]
                      ? _c("p", [
                          _c("span", { staticClass: "capitalize-first" }, [
                            _vm._v(
                              _vm._s(_vm.application.secondaryTenants[0].name)
                            ),
                          ]),
                          _c("span", { staticClass: "light" }, [
                            _vm._v(
                              _vm._s(
                                _vm.application.secondaryTenants[0].address
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.application.secondaryTenants[1]
                      ? _c("p", [
                          _c("span", { staticClass: "capitalize-first" }, [
                            _vm._v(
                              _vm._s(_vm.application.secondaryTenants[1].name)
                            ),
                          ]),
                          _c("span", { staticClass: "light" }, [
                            _vm._v(
                              _vm._s(
                                _vm.application.secondaryTenants[1].address
                              )
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _c("p", [
                      _vm._v(
                        "Hyra: " +
                          _vm._s(_vm.application.apartment?.rent) +
                          " kr"
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "Lägenhetsnr: " +
                          _vm._s(_vm.application.apartment?.apartmentNumber)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " Objektnr: " +
                          _vm._s(_vm.application.apartment?.objectNumber) +
                          " "
                      ),
                    ]),
                  ],
            ],
            2
          ),
        ]),
      ]),
      _c("template", { slot: "footer" }, [
        _vm.application.landlordStatus === "APPROVED"
          ? _c("p", [
              _vm.showTransferDate
                ? _c("i", [_vm._v(_vm._s(_vm.transferDateMsg))])
                : _vm._e(),
            ])
          : _vm.application?.reason.transferDate
          ? _c("p", [
              _vm._v(" Önskat överlåtelsedatum: "),
              _c("br"),
              _c("b", [
                _vm._v(
                  _vm._s(
                    new Date(
                      _vm.application?.reason.transferDate
                    ).toLocaleDateString("sv-SE")
                  ) + " "
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }