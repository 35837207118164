import Vue from 'vue';

function setApps(state, apps) {
  state.apps = apps;
}

function mergeAhApps(state, apps) {
  const appsById = new Map(state.ahApps.map(app => [app.id, app]));

  apps.forEach(newApp => {
    appsById.set(newApp.id, { ...appsById.get(newApp.id), ...newApp });
  });

  state.ahApps = Array.from(appsById.values());
}
function setRemovedApps(state, removedApps) {
  state.removedApps = removedApps;
}

function setApplicationStatus(state, { id, status }) {
  state.apps = state.apps.map(el => (el.id === id ? { ...el, status } : el));
}

function setAhApplicationStatus(state, { id, status }) {
  state.ahApps = state.ahApps.map(el =>
    el.id === id ? { ...el, landlordStatus: status } : el
  );
}

function setAhApplicationDates(state, { id, startDate, endDate }) {
  const index = state.ahApps.findIndex(ahApp => ahApp.id == id);
  if (index === -1) {
    return;
  }

  Vue.set(state.ahApps, index, {
    ...state.ahApps[index],
    startDate,
    endDate
  });
}

function setApplicationUnreadNotes(state, { applicationId, unreadNotes }) {
  const index = state.apps.findIndex(app => app.id == applicationId);
  if (index === -1) {
    return;
  }

  Vue.set(state.apps, index, {
    ...state.apps[index],
    unreadNotes
  });
}

function setApplication(state, obj) {
  const index = state.apps.findIndex(x => x.id == obj.id);

  if (index === -1) return;

  Vue.set(state.apps, index, { ...state.apps[index], ...obj });
}

function setAhApplication(state, obj) {
  const index = state.ahApps.findIndex(x => x.id == obj.id);

  if (index === -1) return;

  Vue.set(state.ahApps, index, { ...state.ahApps[index], ...obj });
}

function setFilter(state, query) {
  state.filter = query;
}

function setFilterCategories(state, filterCategories) {
  state.filterCategories = filterCategories;
}

function setSubletFilterCategories(state, filterCategories) {
  state.subletFilterCategories = filterCategories;
}

function setLandlordUsers(state, users) {
  state.landlordUsers = users;
}

function setSwapDates(state, filterDates) {
  state.activeDates = filterDates;
}

function setAppsLoading(state, isLoading) {
  state.isLoadingApps = isLoading;
}

function toggleSelectedUsers(state, id) {
  state.landlordUsers = state.landlordUsers.map(user => {
    if (user.id === id) {
      return { ...user, selected: !user.selected };
    }
    return user;
  });
}

function toggleSelectedCategories(state, id) {
  state.filterCategories = state.filterCategories.map(cat => {
    if (cat.id === id) {
      return { ...cat, selected: !cat.selected };
    }
    return cat;
  });
}

function toggleSelectedSubletCategories(state, id) {
  state.subletFilterCategories = state.subletFilterCategories.map(cat => {
    if (cat.id === id) {
      return { ...cat, selected: !cat.selected };
    }
    return cat;
  });
}

function setDatePickerValue(state, { type, value }) {
  state.datePickerValue[type] = value;
}

function addTag(apps, applicationId, tagId) {
  const index = apps.findIndex(application => application.id == applicationId);

  if (index === -1) return;

  Vue.set(apps, index, {
    ...apps[index],
    tags: [...apps[index].tags, tagId]
  });
}

function removeTag(apps, applicationId, tagId) {
  const index = apps.findIndex(application => application.id == applicationId);

  if (index === -1) return;

  Vue.set(apps, index, {
    ...apps[index],
    tags: apps[index].tags.filter(id => id !== tagId)
  });
}

function addApplicationTag(state, { applicationId, tagId }) {
  addTag(state.apps, applicationId, tagId);
}

function removeApplicationTag(state, { applicationId, tagId }) {
  removeTag(state.apps, applicationId, tagId);
}

function addAhApplicationTag(state, { applicationId, tagId }) {
  addTag(state.ahApps, applicationId, tagId);
}

function removeAhApplicationTag(state, { applicationId, tagId }) {
  removeTag(state.ahApps, applicationId, tagId);
}

function setFilterTags(state, tags) {
  state.filterTags = tags.map(tag => ({
    id: tag.tagId,
    title: tag.tagName,
    selected: false
  }));
}

function resetFilterTags(state) {
  state.filterTags = state.filterTags.map(tag => ({
    ...tag,
    selected: false
  }));
}

function toggleSelectedTags(state, tagId) {
  state.filterTags = state.filterTags.map(tag => {
    if (tag.id === tagId) {
      return { ...tag, selected: !tag.selected };
    }
    return tag;
  });
}

function setActiveChainId(state, id) {
  state.activeChainId = id;
}

export default {
  setApps,
  mergeAhApps,
  setRemovedApps,
  setApplicationStatus,
  setAhApplicationStatus,
  setApplication,
  setAhApplication,
  setFilter,
  setApplicationUnreadNotes,
  setFilterCategories,
  setLandlordUsers,
  setAppsLoading,
  toggleSelectedUsers,
  toggleSelectedCategories,
  toggleSelectedSubletCategories,
  setSwapDates,
  setDatePickerValue,
  setSubletFilterCategories,
  setAhApplicationDates,
  addApplicationTag,
  removeApplicationTag,
  setFilterTags,
  toggleSelectedTags,
  resetFilterTags,
  addAhApplicationTag,
  removeAhApplicationTag,
  setActiveChainId
};
