var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    { on: { close: _vm.closeModal } },
    [
      _c(
        "template",
        { slot: "header" },
        [_c("DefaultModalTitle", [_vm._v("Hantera taggar")])],
        1
      ),
      _c("template", { slot: "body" }, [
        _c(
          "ul",
          { staticClass: "tag-list" },
          _vm._l(_vm.tags, function (tag) {
            return _c(
              "li",
              { key: tag.tagId },
              [
                _c(
                  "BaseButton",
                  {
                    staticClass: "tag-button",
                    attrs: {
                      "is-disabled": _vm.loading,
                      "is-rounded": true,
                      "is-default": !_vm.applicationTags.includes(tag.tagId),
                      "is-dark": true,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleTagClick(tag.tagId)
                      },
                    },
                  },
                  [_vm._v(_vm._s(tag.tagName))]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "BaseButton",
            {
              attrs: { "is-rounded": true, "is-default": true },
              on: { click: _vm.closeModal },
            },
            [_vm._v("Stäng")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }