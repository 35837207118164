var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasError
    ? _c("ErrorModal", {
        on: {
          close: function ($event) {
            return _vm.$emit("close")
          },
        },
      })
    : _c(
        "DefaultModal",
        {
          on: {
            close: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c(
            "template",
            { slot: "header" },
            [_c("DefaultModalTitle", [_vm._v("Godkänn ansökan?")])],
            1
          ),
          _c("template", { slot: "body" }, [
            _c("div", { style: { textAlign: "center" } }, [
              _c("p", { staticClass: "swap-description" }, [
                !_vm.dateChanged
                  ? _c("span", [_vm._v("Godkänn överlåtelsedatum:")])
                  : _c("span", { staticClass: "rentchangetitle" }, [
                      _vm._v("Godkänn med nytt datum:"),
                    ]),
              ]),
              _c("div", { staticClass: "date-container" }, [
                _c(
                  "div",
                  { staticClass: "datePeriod" },
                  [
                    _c("Input", {
                      class: { hasChanged: _vm.dateChanged },
                      attrs: {
                        label: "Överlåtelsedatum",
                        type: "date",
                        min: new Date().toISOString().split("T")[0],
                      },
                      model: {
                        value: _vm.actualTransferDateProxy,
                        callback: function ($$v) {
                          _vm.actualTransferDateProxy = $$v
                        },
                        expression: "actualTransferDateProxy",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm.dateChanged
                ? _c("p", { staticClass: "wish-period" }, [
                    _vm._v(" Önskat datum: "),
                    _c("br"),
                    _c("b", [
                      _c("span", { class: { hasChanged: _vm.dateChanged } }, [
                        _vm._v(_vm._s(_vm.transferDateProxy)),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
          ]),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "BaseButton",
                {
                  attrs: {
                    "is-loading": _vm.isSaving,
                    "is-rounded": true,
                    icon: _vm.svgs.ICONS.CHECK_WHITE,
                  },
                  on: { click: _vm.approveOvApplication },
                },
                [_vm._v("Godkänn ansökan")]
              ),
              _c(
                "BaseButton",
                {
                  attrs: {
                    "is-disabled": _vm.isSaving,
                    "is-rounded": true,
                    "is-default": true,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [_vm._v("Avbryt")]
              ),
            ],
            1
          ),
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }