<template>
  <ApplicationsLane
    v-if="applications"
    :title="title"
    :applications="applications"
    :container-style="containerStyle"
    :status="status"
  >
    <template slot="controls">
      <SelectWrapper v-if="status === 'waiting'">
        <select v-model="activeSorting">
          <option value="sentToHostAt-NEW">Nyaste ansökan</option>
          <option value="sentToHostAt-OLD">Äldsta ansökan</option>
          <option value="rentFrom-NEW">Uthyrningsdatum (senare)</option>
          <option value="rentFrom-OLD">Uthyrningsdatum (tidigast)</option>
        </select>
      </SelectWrapper>
      <SelectWrapper v-else hidden>
        <select disabled></select>
      </SelectWrapper>
    </template>
    <template slot="body">
      <transition-group
        v-for="(groupedApplication, date, index) in groupedApplications"
        :key="date"
        name="fade-list"
        class="relative"
        :class="{ 'card-group': status === 'closed' }"
        tag="div"
        appear
      >
        <div v-if="status === 'approved'" :key="index" class="app-date">
          {{ getGroupText(date) }}
        </div>
        <SubletApplicationCard
          v-for="application in groupedApplication"
          :key="application.id"
          :application="application"
          :reason-type-title="reasonText(application.reason)"
          :is-new="cardIsNew(application)"
          :full-name="fullName(application)"
          :last-name="lastName(application)"
          :status-date="
            getStatusDate(
              status,
              application.statusUpdated,
              application.sentToHost
            )
          "
          :to-path="toPath"
        />
      </transition-group>
    </template>
  </ApplicationsLane>
</template>

<script>
import statusTypes from '@/utils/statusTypes';
import { reasonText } from '@/utils/subletUtils';

import ApplicationsLane from '@/components/ApplicationsLane.vue';
import SubletApplicationCard from '@/components/SubletApplicationCard.vue';
import SelectWrapper from '@/components/SelectWrapper.vue';

export default {
  name: 'SubletApplicationsLane',
  components: {
    SubletApplicationCard,
    ApplicationsLane,
    SelectWrapper
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    containerStyle: {
      type: Object,
      default: null
    },
    applications: {
      type: [Array, null],
      default: null
    },
    toPath: {
      type: String,
      default: '/andrahand/alla'
    }
  },
  data() {
    return {
      reasonText,
      activeSorting: 'sentToHostAt-NEW'
    };
  },
  computed: {
    closedStatuses() {
      return [
        statusTypes.CLOSED,
        statusTypes.REJECTED,
        statusTypes.APPROVED,
        statusTypes.ABORTED
      ];
    },
    groupedApplications() {
      const groups = {};
      const newApplications = this.applications;
      if (this.status !== 'waiting') {
        newApplications.sort((a, b) => {
          const aDate = new Date(a.endDate);
          const bDate = new Date(b.endDate);
          return aDate - bDate;
        });

        newApplications.forEach(function (application) {
          let date = application.endDate
            ? new Date(application.endDate).toISOString().slice(0, 7)
            : '-';
          if (date in groups) {
            groups[date].push(application);
          } else {
            groups[date] = new Array(application);
          }
        });

        return groups;
      } else {
        return {
          0: newApplications.sort((a, b) => {
            if (this.activeSorting.includes('sentToHostAt')) {
              const aSentToHost = new Date(a.sentToHostAt).getTime();
              const bSentToHost = new Date(b.sentToHostAt).getTime();
              if (this.activeSorting.includes('NEW')) {
                return bSentToHost - aSentToHost;
              }
              return aSentToHost - bSentToHost;
            }
            if (this.activeSorting.includes('rentFrom')) {
              const aRentFrom = new Date(a.reason?.rentFrom).getTime();
              const bRentFrom = new Date(b.reason?.rentFrom).getTime();
              if (this.activeSorting.includes('NEW')) {
                return bRentFrom - aRentFrom;
              }
              return aRentFrom - bRentFrom;
            }
            return true;
          })
        };
      }
    }
  },
  methods: {
    getStatusDate(status, statusUpdated, sentToHost) {
      if (this.closedStatuses.includes(status)) {
        return statusUpdated ? statusUpdated.substring(0, 10) : '';
      }

      return sentToHost
        ? new Date(Number(sentToHost)).toISOString().slice(0, 10)
        : '';
    },
    getGroupText(monthDate) {
      const month = monthDate.slice(5, 7);
      const fullYear = monthDate.slice(0, 4);
      const startText = 'Löper ut i';
      switch (month) {
        case '01':
          return `${startText} Januari (${fullYear})`;
        case '02':
          return `${startText} Februari (${fullYear})`;
        case '03':
          return `${startText} Mars (${fullYear})`;
        case '04':
          return `${startText} April (${fullYear})`;
        case '05':
          return `${startText} Maj (${fullYear})`;
        case '06':
          return `${startText} Juni (${fullYear})`;
        case '07':
          return `${startText} Juli (${fullYear})`;
        case '08':
          return `${startText} Augusti (${fullYear})`;
        case '09':
          return `${startText} September (${fullYear})`;
        case '10':
          return `${startText} Oktober (${fullYear})`;
        case '11':
          return `${startText} November (${fullYear})`;
        case '12':
          return `${startText} December (${fullYear})`;
        default:
          return '';
      }
    },
    fullName(application) {
      if (!application.assignedLandlordFirstName) {
        return '';
      }
      return application.assignedLandlordFirstName;
    },
    lastName(application) {
      if (!application.assignedLandlordLastName) {
        return '';
      }
      return application.assignedLandlordLastName;
    },
    cardIsNew(application) {
      return application.status === statusTypes.READY && application.isNew;
    }
  }
};
</script>

<style scoped>
.app-date {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 0.3rem;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.card-group > *:nth-last-child(1) {
  margin-bottom: 3rem;
}
</style>
