var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    { on: { close: _vm.closeModal } },
    [
      _c(
        "template",
        { slot: "header" },
        [
          _c("DefaultModalTitle", [
            _vm._v(
              " " +
                _vm._s(
                  !_vm.loading ? "Generera sammanfogad PDF" : "Genererar PDF"
                ) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "body" },
        [
          _vm.hasError
            ? _c("ErrorModal", {
                attrs: {
                  message:
                    "Det gick inte att hämta filerna, försök igen senare",
                },
                on: {
                  close: function ($event) {
                    _vm.hasError = false
                  },
                },
              })
            : _vm._e(),
          _c("div", { staticClass: "wrapper" }, [
            _vm.loading
              ? _c("div", { staticClass: "spinner-container" }, [
                  _c("div", { staticClass: "spinner-box" }, [
                    _c(
                      "div",
                      { staticClass: "spinner" },
                      [_c("BaseSpinner")],
                      1
                    ),
                    _c("p", { class: { visible: _vm.showLoadingInfo } }, [
                      _vm._v(" Det kan ta en stund att generera filen,"),
                      _c("br"),
                      _vm._v("vänligen vänta "),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "file-browser-wrapper",
                class: { "is-loading": _vm.loading },
              },
              [
                _c(
                  "div",
                  { staticClass: "file-browser-scroll" },
                  [
                    _vm.hasScriveDocument
                      ? _c("div", { staticClass: "folder-name" }, [
                          _c(
                            "label",
                            { staticClass: "folder-row" },
                            [
                              _c("BaseCheckbox", {
                                staticClass: "checkbox",
                                attrs: { checked: _vm.scriveChecked },
                                on: { change: _vm.toggleScriveChecked },
                              }),
                              _c("BaseImage", {
                                staticClass: "icon",
                                attrs: { url: _vm.svgs.ICONS.PDF },
                              }),
                              _c("div", { staticClass: "folder-info" }, [
                                _vm._v("Signerad ansökan"),
                              ]),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._l(
                      _vm.checkedDocumentCollection,
                      function (collection, applicantId) {
                        return _c(
                          "div",
                          { key: applicantId },
                          [
                            _c("div", { staticClass: "folder-row" }, [
                              _c(
                                "label",
                                { staticClass: "folder-info underline" },
                                [
                                  _c("BaseCheckbox", {
                                    staticClass: "checkbox",
                                    attrs: {
                                      checked: _vm.docsCheckedFor(applicantId),
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.toggleCollectionChecked(
                                          applicantId
                                        )
                                      },
                                    },
                                  }),
                                  _c("div", { staticClass: "folder-label" }, [
                                    _c("div", { staticClass: "folder-name" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            !_vm.isAhOrOv
                                              ? _vm.getSharedFolderName(
                                                  applicantId
                                                )
                                              : applicantId
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("div", { staticClass: "file-meta" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            collection ? collection.length : 0
                                          ) +
                                          " uppladdade filer "
                                      ),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                            _vm.isAhOrOv
                              ? _vm._l(collection, function (file) {
                                  return _c(
                                    "label",
                                    { key: file.id, staticClass: "folder-row" },
                                    [
                                      _c("BaseCheckbox", {
                                        staticClass: "checkbox",
                                        attrs: {
                                          checked:
                                            file?.checked === false
                                              ? false
                                              : true,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.toggleDocumentChecked(
                                              applicantId,
                                              file.id
                                            )
                                          },
                                        },
                                      }),
                                      _c("BaseImage", {
                                        staticClass: "icon",
                                        attrs: {
                                          url: _vm.docTypeUtils.getFileIcon(
                                            file.fileName
                                          ),
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "folder-info" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "folder-name" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.docTypeUtils.getFilename(
                                                      file.fileName
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "file-meta" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.docTypeUtils.getDocTypeStr(
                                                      file.type
                                                    )
                                                  ) +
                                                  " / "
                                              ),
                                              _c(
                                                "span",
                                                { staticClass: "file-type" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.docTypeUtils.getFileExtension(
                                                        file.fileName
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(
                                                ", " +
                                                  _vm._s(
                                                    _vm.docTypeUtils.getHumanFileSize(
                                                      file.fileSize
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                })
                              : _vm._l(
                                  _vm.getCollectionGroup(
                                    collection,
                                    applicantId
                                  ),
                                  function (groupCollection, currentIndex) {
                                    return _c(
                                      "div",
                                      {
                                        key: currentIndex,
                                        staticClass: "sub-group",
                                      },
                                      [
                                        groupCollection.label &&
                                        groupCollection.docs?.length
                                          ? _c(
                                              "div",
                                              { staticClass: "folder-row" },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "folder-info underline",
                                                  },
                                                  [
                                                    _c("BaseCheckbox", {
                                                      staticClass: "checkbox",
                                                      attrs: {
                                                        checked:
                                                          _vm.docsCheckedForSubgroup(
                                                            applicantId,
                                                            groupCollection.index
                                                          ),
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.toggleSubGroupChecked(
                                                            applicantId,
                                                            groupCollection.index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "folder-label",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "folder-name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  groupCollection.label
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "file-meta",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  groupCollection
                                                                    .docs.length
                                                                ) +
                                                                " uppladdade filer "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._l(
                                          groupCollection.docs,
                                          function (file) {
                                            return _c(
                                              "label",
                                              {
                                                key: file.id,
                                                staticClass: "folder-row",
                                              },
                                              [
                                                _c("BaseCheckbox", {
                                                  staticClass: "checkbox",
                                                  attrs: {
                                                    checked:
                                                      file?.checked === false
                                                        ? false
                                                        : true,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.toggleDocumentChecked(
                                                        applicantId,
                                                        file.id
                                                      )
                                                    },
                                                  },
                                                }),
                                                _c("BaseImage", {
                                                  staticClass: "icon",
                                                  attrs: {
                                                    url: _vm.docTypeUtils.getFileIcon(
                                                      file.fileName
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "folder-info",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "folder-name",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.docTypeUtils.getFilename(
                                                                file.fileName
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "file-meta",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.docTypeUtils.getDocTypeStr(
                                                                file.type
                                                              )
                                                            ) +
                                                            " / "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "file-type",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.docTypeUtils.getFileExtension(
                                                                  file.fileName
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          ", " +
                                                            _vm._s(
                                                              _vm.docTypeUtils.getHumanFileSize(
                                                                file.fileSize
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    )
                                  }
                                ),
                          ],
                          2
                        )
                      }
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "BaseButton",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
              attrs: {
                "is-rounded": true,
                icon: _vm.svgs.ICONS.DOWNLOAD,
                "is-disabled": _vm.loading || _vm.customCheckedAmount === 0,
              },
              on: { click: _vm.submitSelected },
            },
            [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
          ),
          _vm.loading
            ? _c(
                "BaseButton",
                {
                  attrs: { "is-rounded": true, "is-default": true },
                  on: { click: _vm.cancelRequest },
                },
                [_vm._v("Avbryt")]
              )
            : _vm.customCheckedAmount === null
            ? _c(
                "BaseButton",
                {
                  attrs: { "is-rounded": true, "is-default": true },
                  on: { click: _vm.closeModal },
                },
                [_vm._v("Tillbaka")]
              )
            : _c(
                "BaseButton",
                {
                  attrs: { "is-rounded": true, "is-default": true },
                  on: { click: _vm.resetChecked },
                },
                [_vm._v("Ångra")]
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }