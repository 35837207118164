<template>
  <div :title="text">
    <LinkChainIcon
      :colors="reverse ? ['#95032d', '#38389b'] : ['#38389b', '#95032d']"
    />
  </div>
</template>
<script>
import LinkChainIcon from '@/components/Icons/LinkChainIcon.vue';

export default {
  name: 'LinkChain',
  components: {
    LinkChainIcon
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    reverse: {
      type: Boolean,
      required: true
    }
  }
};
</script>
<style scoped>
div {
  height: 2.8rem;
  width: 2.8rem;
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding: 0;
  background-color: transparent;
}
</style>
