var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DefaultModal",
    {
      on: {
        close: function ($event) {
          return _vm.$emit("close")
        },
      },
    },
    [
      _c(
        "template",
        { slot: "header" },
        [_c("DefaultModalTitle", [_vm._v("Markera som avslutad?")])],
        1
      ),
      _c("template", { slot: "body" }, [
        _c("p", { staticClass: "swap-description" }, [
          _vm._v("Ärendet blir markerat som avslutad."),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "BaseButton",
            {
              attrs: {
                "is-warning": true,
                "is-loading": _vm.loading,
                "is-rounded": true,
                icon: _vm.svgs.ICONS.ERROR_WHITE,
              },
              on: { click: _vm.cancelApplication },
            },
            [_vm._v(" Markera som avslutad ")]
          ),
          _c(
            "BaseButton",
            {
              attrs: {
                "is-disabled": _vm.loading,
                "is-rounded": true,
                "is-default": true,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("Avbryt")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }