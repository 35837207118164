<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5438 10.456C12.7247 9.6372 11.614 9.17725 10.4558 9.17725C9.29766 9.17725 8.18692 9.6372 7.36781 10.456L4.27881 13.544C3.45969 14.3631 2.99951 15.474 2.99951 16.6325C2.99951 17.7909 3.45969 18.9018 4.27881 19.721C5.09793 20.5401 6.2089 21.0003 7.36731 21.0003C8.52572 21.0003 9.63669 20.5401 10.4558 19.721L11.9998 18.177"
      :stroke="colors[0]"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4561 13.5438C11.2752 14.3626 12.3859 14.8225 13.5441 14.8225C14.7022 14.8225 15.8129 14.3626 16.6321 13.5438L19.7211 10.4558C20.5402 9.63669 21.0004 8.52572 21.0004 7.36731C21.0004 6.2089 20.5402 5.09793 19.7211 4.27881C18.9019 3.45969 17.791 2.99951 16.6326 2.99951C15.4741 2.99951 14.3632 3.45969 13.5441 4.27881L12.0001 5.82281"
      :stroke="colors[1]"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'LinkChainIcon',
  props: {
    height: {
      type: String,
      default: '24'
    },
    width: {
      type: String,
      default: '24'
    },
    colors: {
      type: Array,
      default: () => ['#2C3E50', '#2C3E50']
    }
  }
};
</script>
