var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ApplicationsRow",
    { attrs: { "two-columns": "" } },
    [
      _c(
        "ApplicationCard",
        { attrs: { "dark-bg": _vm.activePartOneIndex === 1 } },
        [
          _c(
            "template",
            { slot: "top-content" },
            [
              _c("h4", [_vm._v("Överlåtaren")]),
              _c("PersonCard", {
                attrs: {
                  name: _vm.currentTenantCompanyInfo?.name || "",
                  address: _vm.currentTenantCompanyInfo?.address || "",
                },
              }),
              _c("MemberSelector", {
                attrs: {
                  items: [
                    {
                      label: "Företaget",
                    },
                    ..._vm.currentTenants.map((tenant, index) => ({
                      ...tenant,
                      label: `Firmatecknare ${index + 1}`,
                    })),
                  ],
                  "active-index": _vm.activePartOneIndex,
                },
                on: { selectItemIndex: _vm.selectCurrentTenantIndex },
              }),
            ],
            1
          ),
          _vm.activePartOneIndex === 0
            ? [
                _c("h3", { staticClass: "name capitalize-first" }, [
                  _vm._v(
                    " " + _vm._s(_vm.currentTenantCompanyInfo?.name || "") + " "
                  ),
                ]),
                _c("CompanyModule", {
                  attrs: { company: _vm.currentTenantCompanyInfo },
                }),
                _c("hr", { staticClass: "divider" }),
                _c("div", { staticClass: "card-content" }, [
                  _c("h4", [_vm._v("Företagets verksamhet")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.currentTenantCompanyInfo?.activities || "")
                    ),
                  ]),
                ]),
              ]
            : [
                _c("h3", { staticClass: "name capitalize-first" }, [
                  _vm._v(
                    " " + _vm._s(_vm.activeCurrentTenant?.name || "") + " "
                  ),
                ]),
                _c("ul", { staticClass: "apartment-info" }, [
                  _c("li", [
                    _c("b", [_vm._v("E-post:")]),
                    _vm._v(" " + _vm._s(_vm.activeCurrentTenant?.email || "")),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v("Telefonnr.:")]),
                    _vm._v(" " + _vm._s(_vm.activeCurrentTenant.phone) + " "),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v("Adress:")]),
                    _vm._v(
                      " " + _vm._s(_vm.activeCurrentTenant?.address || "")
                    ),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v("Postnr.:")]),
                    _vm._v(
                      " " + _vm._s(_vm.activeCurrentTenant?.postalCode || "")
                    ),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v("Ort:")]),
                    _vm._v(" " + _vm._s(_vm.activeCurrentTenant?.city || "")),
                  ]),
                ]),
              ],
          _c("hr", { staticClass: "divider" }),
          _c("div", { staticClass: "card-content" }, [
            _c("h4", [_vm._v("Verksamhet som ska bedrivas i lokalen")]),
            _c("p", [_vm._v(_vm._s(_vm.application.reason.text))]),
          ]),
          _c("hr", { staticClass: "divider" }),
          _c("OvApplicationCheckboxes", {
            attrs: {
              files: _vm.application.files,
              application: _vm.application,
              "application-id": _vm.application.id,
              "checked-files": _vm.application.checkedFiles,
              "other-checkboxes": _vm.otherCheckboxes,
              "application-status": _vm.application.landlordStatus,
            },
            on: {
              openFile: function ($event) {
                return _vm.$emit("openFile", $event)
              },
              checksChange: function ($event) {
                return _vm.$emit("checksChange", $event)
              },
            },
          }),
        ],
        2
      ),
      _c(
        "ApplicationCard",
        { attrs: { "dark-bg": _vm.activePartTwoIndex === 1 } },
        [
          _c(
            "template",
            { slot: "top-content" },
            [
              _c("h4", [_vm._v("Övertagaren")]),
              _c("PersonCard", {
                attrs: {
                  name: _vm.secondaryTenantCompanyInfo?.name || "",
                  address: _vm.secondaryTenantCompanyInfo?.address || "",
                },
              }),
              _c("MemberSelector", {
                attrs: {
                  items: [
                    {
                      label: "Företaget",
                    },
                    ..._vm.secondaryTenants.map((tenant, index) => ({
                      ...tenant,
                      label: `Firmatecknare ${index + 1}`,
                    })),
                  ],
                  "active-index": _vm.activePartTwoIndex,
                },
                on: { selectItemIndex: _vm.selectSecondaryTenantIndex },
              }),
            ],
            1
          ),
          _vm.activePartTwoIndex === 0
            ? [
                _c("h3", { staticClass: "name capitalize-first" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.secondaryTenantCompanyInfo?.name || "") +
                      " "
                  ),
                ]),
                _c("CompanyModule", {
                  attrs: { company: _vm.secondaryTenantCompanyInfo },
                }),
                _c("hr", { staticClass: "divider" }),
                _c("div", { staticClass: "card-content" }, [
                  _c("h4", [_vm._v("Företagets verksamhet")]),
                  _c("p", [
                    _vm._v(
                      _vm._s(_vm.secondaryTenantCompanyInfo?.activities || "")
                    ),
                  ]),
                ]),
              ]
            : [
                _c("h3", { staticClass: "name capitalize-first" }, [
                  _vm._v(
                    " " + _vm._s(_vm.activeSecondaryTenant?.name || "") + " "
                  ),
                ]),
                _c("ul", { staticClass: "apartment-info" }, [
                  _c("li", [
                    _c("b", [_vm._v("E-post:")]),
                    _vm._v(
                      " " + _vm._s(_vm.activeSecondaryTenant?.email || "")
                    ),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v("Telefonnr.:")]),
                    _vm._v(" " + _vm._s(_vm.activeSecondaryTenant.phone) + " "),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v("Adress:")]),
                    _vm._v(
                      " " + _vm._s(_vm.activeSecondaryTenant?.address || "")
                    ),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v("Postnr.:")]),
                    _vm._v(
                      " " + _vm._s(_vm.activeSecondaryTenant?.postalCode || "")
                    ),
                  ]),
                  _c("li", [
                    _c("b", [_vm._v("Ort:")]),
                    _vm._v(" " + _vm._s(_vm.activeSecondaryTenant?.city || "")),
                  ]),
                ]),
              ],
          _c("hr", { staticClass: "divider" }),
          _c("OvApplicationCheckboxes", {
            attrs: {
              files: _vm.application.files,
              application: _vm.application,
              "application-id": _vm.application.id,
              "checked-files": _vm.application.checkedFiles,
              "application-status": _vm.application.landlordStatus,
              "is-transferor": false,
            },
            on: {
              openFile: function ($event) {
                return _vm.$emit("openFile", $event)
              },
              checksChange: function ($event) {
                return _vm.$emit("checksChange", $event)
              },
            },
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }